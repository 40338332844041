import { createTheme } from "@mui/material";
import { blue, green, red, grey, yellow } from "@mui/material/colors";
import { esES as dataGridEsES } from '@mui/x-data-grid';
import { esES as coreEsES } from '@mui/material/locale';
import { esES } from '@mui/x-date-pickers/locales';

//https://zenoo.github.io/mui-theme-creator/
//https://mui.com/material-ui/customization/default-theme/
const RabbitLight = createTheme(
    {
        palette:{
            mode: "light",
            background: {
                default: "#EBEAE0",
                paper: "#EBEAE0",
                close: "#e0e0d7"
            },
            primary: {
                main: "#9D11F2",
            },
            secondary: {
                main: "#C85DF0",
            },
            success:{
                main: "#00EA84",
            },
            warning: {
                main: "#F0C300",
            },
            error: {
                main: "#FE0037",
            },
            info:{
                main: "#2F43FF",
            }
        },
    },
    esES,
    dataGridEsES,
    coreEsES,
);

const RabbitDark = createTheme(
    {
        palette:{
            mode: "dark",
            background: {
                default: '#190007',
                paper: "#190007",
                close: "#362129",
            },
            primary: {
                main: "#9D11F2",
            },
            secondary: {
                main: "#C85DF0",
            },
            success:{
                main: "#00EA84",
            },
            warning: {
                main: "#F0C300",
            },
            error: {
                main: "#FE0037",
            },
            info:{
                main: "#2F43FF",
            }
        }
    },
    esES,
    dataGridEsES,
    coreEsES,
);

const LightTheme = createTheme(
    {
        palette:{
            mode: "light",
            background: {
                default: '#fff',
                paper: "#fff",
                close: grey[200],
            },
            primary: {
                main: green[900],
            },
            secondary: {
                main: blue[900],
            },
            success:{
                main: green[900],
            },
            warning: {
                main: yellow[800],
            },
            error: {
                main: red[500],
            },
            info:{
                main: blue[900],
            }
        },
    },
    esES,
    dataGridEsES,
    coreEsES,
);

const DarkTheme = createTheme(
    {
        palette:{
            mode: "dark",
            background: {
                default: '#000',
                paper: "#000",
                close: grey[900],
            },
            primary: {
                main: green[900],
            },
            secondary: {
                main: blue[900],
            },
            success:{
                main: green[900],
            },
            warning: {
                main: yellow[800],
            },
            error: {
                main: red[500],
            },
            info:{
                main: blue[900],
            }
        }
    },
    esES,
    dataGridEsES,
    coreEsES,
);

export {
    RabbitLight,
    RabbitDark,
    LightTheme,
    DarkTheme,
}