import React from "react";
import Navbar from "../../components/navigation/Navbar";
import { useTranslation } from "react-i18next";
import { ContextProvider } from "../../components/table/timecard/Context";
import { Table } from "../../components/table/timecard/TableSingle";

const Timecard = () => {
    const { t } = useTranslation();

    return <>
        <Navbar
            view = {{
                title: t('timecard.title'),
                component: 
                    <ContextProvider>
                        {/* <TimeTable mobile={false} /> */}
                        <Table />
                    </ContextProvider>
            }}
            right = {{
                title: t('timecard.title'),
                component: <> componente </>
            }}
        />
    </>
}

export {Timecard}