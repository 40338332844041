import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '../../table/timecard/Context'
import { GlobalContext } from '../../../GlobalContext'
import { LoadingButton } from '@mui/lab'
import { applyingOvertime } from '../../../utils/Overtime'

const RecantOvertime = ({open, setOpen, data}) => {
    const { t } = useTranslation()

    const {
        amount,
        reloading,
    } = useContext(Context)

    const {
        handlerAlert,
    } = useContext(GlobalContext)

    const [date, setDate] = useState(null)
    const [partial, setPartial] = useState(null)

    const [loading, setLoading] = useState(false)

    const closing = () => {
        setOpen(false)
        setDate(null)
        setPartial(null)
        setLoading(false)
    }

    useEffect(() => {
        if(open){
            setDate(amount)
            setPartial(amount.format('HH:mm'))
        }

    }, [amount, data, open])

    return (
        <>
            <Dialog
                fullWidth
                open={open} 
                onClose={() => { closing() }}
            >
                <DialogTitle
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Typography variant='body3'>{ t('timecard.table.modals.recant_overtime.title') }</Typography>
                </DialogTitle>

                <DialogContent
                    dividers
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Typography variant='body3'>{ t('timecard.table.modals.recant_overtime.message', {amount: amount.format('HH:mm'), name: data[0]?.name}) }</Typography>
                </DialogContent>


                <DialogActions
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <LoadingButton
                        variant='contained'
                        disableElevation 
                        onClick={() => {
                            const overtimes = [{
                                person_number: data[0].id,
                                date: date.format('YYYY-MM-DD'),
                                action: 'resetapprovals',
                                partial_amount: partial,
                                comment: undefined,
                                notes: undefined,
                            }]

                            console.log(overtimes)

                            applyingOvertime(
                                setLoading,
                                overtimes,
                                closing,
                                reloading,
                                handlerAlert,
                            )
                        }}
                        color='primary'
                        loading={loading}
                        disabled={loading}
                    >
                        {t('timecard.table.modals.recant_overtime.buttons.recant')}
                    </LoadingButton>


                    <Button
                        variant='contained' 
                        disableElevation 
                        color='error' 
                        onClick={() => { closing() }}
                        sx={{mr: 1.5}}
                    >
                        {t('timecard.table.modals.recant_overtime.buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export {
    RecantOvertime
}