import { Box,Grid } from "@mui/material"
import { DateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro"
import { shortcuts } from "../../utils/Dates"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import { useContext } from "react"
import { gettingTotals } from "../../utils/Total"
import { GlobalContext } from "../../GlobalContext"
import { Context } from "../table/timecard/Context"
//import { gettingAudit } from "../../utils/Audits"


// Audit
const FilterTotal = ({loading, setLoading, setRows, employee_id}) => {
    const { t } = useTranslation()

    const { 
        handelerAlert,
    } = useContext(GlobalContext)

    const {
        range3,
        setRange3,
    } = useContext(Context)

    const searching = () => {
        gettingTotals(
            setLoading, 
            range3,
            employee_id, 
            setRows, 
            handelerAlert
        )
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex', 
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 2,
                    p: 1.5,
                    borderBottom: theme => `1px solid ${theme.palette.divider}`, 
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <DateRangePicker
                            label={t('totals.table.filter.range_of_days')}
                            slots={{ field: SingleInputDateRangeField }}
                            slotProps={{
                                shortcuts: {
                                    items: shortcuts(t),
                                },
                            }}
                            format={JSON.parse(sessionStorage.getItem('config')).date_format}
                            calendars={1}
                            fullwith
                            onAccept={(value) => { setRange3(value) }}
                            value={range3}
                            sx={{
                                width: '100%'
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}/>

                    <Grid item xs={2}>
                        <LoadingButton
                            sx={{position: 'absolute', right: '15px', height: '56px'}} 
                            loading={loading}
                            variant='contained'
                            onClick={searching}
                            disableElevation
                        >
                            {t('totals.table.filter.search')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export { FilterTotal }