import axios from "axios"
import { signingOut } from "../Login";

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_DOMAIN}/`,
    timeout: 1000 * 60,
    headers: {
        appkey: process.env.REACT_APP_API_APPKEY,
        Authorization: sessionStorage.getItem('token'),
    },
});

instance.interceptors.request.use(
    (config) => {
        config.headers.Authorization = sessionStorage.getItem('token');
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if(error.response && error.response.status === 401){
            switch(JSON.parse(sessionStorage.getItem('account'))?.signin){
                case 'MS_SSO':
                    signingOut(instance)
                    break
                default:
                    console.log('signing out')
                    sessionStorage.clear()
                    localStorage.clear()
                    window.location.replace('/signin')
            }
        }

        return Promise.reject(error);
    }
);


export { instance }


