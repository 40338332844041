import { Box, Chip } from '@mui/material'
import React from 'react'

const renderCell = (params) => {
    return (
        <>
            <Box 
                id={`${params?.field}-cell-${params?.row?.id}`}
                sx={{
                    display: 'grid', 
                    placeItems: 'center',
                    width: '100%',
                    height: '100%',
                    gap: '10px',
                    p: 1.5
                }}
            >
                { params?.value ?
                    <Chip sx={{width: '80px'}} label="activo" color='success'/>
                    :
                    <Chip sx={{width: '80px'}} label="inactivo" color='error'/>
                }
            </Box>
        </>
    )
}

export {
    renderCell
}