import { instance } from "./session/axios"
import { saveReload } from "./SessionStorage"

const gettingTotals = async (
    setLoading,
    range,
    person_number,
    setRows,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).timecard.get_timecard){
        setLoading(true)

        const params = {
            start_date: range[0].format('YYYY-MM-DD'),
            end_date: range[1].format('YYYY-MM-DD'),
            person_number,
        }

        await instance.get('timecard', { params })
        .then(response => {
            const tpcs =  Object.keys(response?.data?.total_period_data?.[person_number]?.totals)

            setRows(tpcs.map(tpc => ({
                id: tpc,
                paycode: tpc,
                cost_center: response?.data?.total_period_data?.[person_number]?.totals[tpc]?.labor_account_description,
                amount: response?.data?.total_period_data?.[person_number]?.totals[tpc]?.amount_in_time,
            })))

            saveReload(
                'totals', 
                range[0].format('YYYY-MM-DD'), 
                range[1].format('YYYY-MM-DD'), 
                [],
                [person_number], 
            )
        })
        .catch(error => {
            console.error(error.message)
        })
        .finally(() => {
            setLoading(false)
        })
    }else{
        handlerAlert(
            'timecard.table.alerts.get_timecard.title', 
            'timecard.table.alerts.get_timecard.message',
            'error',
        )
    }
}

export {
    gettingTotals
}