import moment from "moment"
import { instance } from "./session/axios"
import { daysBetween } from "./Dates"
import { saveReload } from "./SessionStorage"

const gettingTotalWorkedHours = (params, apiRef) => {
    const rows = params?.rowNode?.children ? params?.rowNode?.children.map(id => apiRef.current.getRow(id)) : []

    const totals = rows.reduce((acc, row) => {
        const time = row?.worked_hours.split(':')

        const hours = parseInt(time[0])
        const minutes = parseInt(time[1])

        const duration = moment.duration({ hours, minutes })

        return acc.add(duration)
    }, moment.duration(0))

    return `${Math.floor(totals.asHours())}:${(totals.minutes().toString().padStart(2, '0'))}`;
}

const gettingTotalOvertime = (params, apiRef) => {
    const rows = params?.rowNode?.children ? params?.rowNode?.children.map(id => apiRef.current.getRow(id)) : []

    const totals = rows.reduce((acc, row) => {
        const time = row?.overtime.pending_overtime.amount.split(':')

        const hours = parseInt(time[0])
        const minutes = parseInt(time[1])

        const duration = moment.duration({ hours, minutes })

        return acc.add(duration)
    }, moment.duration(0))

    return `${Math.floor(totals.asHours())}:${(totals.minutes().toString().padStart(2, '0'))}`;
}

const gettingShiftStatus = (
    t,
    params, 
    apiRef, 
    cacheRange1, 
) => {
    const now = moment(new Date())

    if(now.isBetween(cacheRange1[0], cacheRange1[1])){
        const row_id = params?.rowNode?.children?.find(id => id.slice(-10) === now.format('YYYY-MM-DD'))
        const row = apiRef.current.getRow(apiRef.current.getAllRowIds().find(id => id === row_id))

        if(row === null){
            return t('timecard.table.cells.interpreting')
        }

        const shift = row?.shifts?.find(shift => now.isBetween(moment(`${shift.date} ${shift.start}`, 'YYYY-MM-DD HH:mm'), moment(`${shift.date} ${shift.end}`, 'YYYY-MM-DD HH:mm')))
        
        const in_punches = row?.punch_in || [] 
        const pairs = []

        for(let punch of in_punches){
            const last = pairs[pairs.length - 1]

            if(pairs.length > 0 && last.out === undefined){
                last.out = punch.time
            }

            const pair = {
                date: punch.date,
                in: punch.date_time, // undefined o la hora de entrada
                out: row?.punch_out?.find(p => p.total_span_index === punch.total_span_index)?.date_time,
                total_span_index: punch.total_span_index,
                exception: punch.exception
            }

            pairs.push(pair)
        }

        if(row?.shifts?.length > 0){
            // tengo un turno activo
            if(shift){
                // 1-) Trabajando
                // 2-) Ausente
                // 3-) Trabajando con excepción
                // 4-) Turno finalizado con excepción

                const pairs_overlap = pairs.map(pair => ({ ...pair }))
                .filter(pair => {
                    return moment(`${shift.date} ${shift.start}`, 'YYYY-MM-DD HH:mm').isBefore(moment(pair.out ? pair.out : `${pair.date} 23:59`, 'YYYY-MM-DD HH:mm')) && moment(pair.in ? pair.in : `${pair.date} 00:00`, 'YYYY-MM-DD HH:mm').isBefore(moment(`${shift.date} ${shift.end}`, 'YYYY-MM-DD HH:mm'))
                })

                if(pairs_overlap.length > 0){
                    if(pairs_overlap.filter(pair => pair.exception === true).length > 0){
                        if(pairs_overlap.filter(pair => pair.out === undefined).length > 0){
                            return t('timecard.table.cells.working_with_some_exceptions')
                        }
                        else{
                            return t('timecard.table.cells.shift_ended_with_exceptions')
                        }
                    }else{
                        if(pairs_overlap.filter(pair => pair.out === undefined).length > 0){
                            return t('timecard.table.cells.working')
                        }else{
                            return t('timecard.table.cells.shift_ended')
                        }
                    }
                }else{
                    return t('timecard.table.cells.absent')
                }

            }else{
                // 1-) Turno finalizado
                // 2-) Turno sin comenzar

                if(row?.shifts?.filter(shift => now.isBefore(moment(`${shift.date} ${shift.start}`, 'YYYY-MM-DD HH:mm'))).length > 0){
                    return t('timecard.table.cells.waiting_shift')
                }

                if(row?.shifts?.filter(shift => now.isAfter(moment(`${shift.date} ${shift.end}`, 'YYYY-MM-DD HH:mm'))).length > 0){
                    return t('timecard.table.cells.shift_ended')
                }
            }
        }else{
            if(pairs.length > 0){    
                const last_pair = pairs[pairs.length - 1]

                if(last_pair.out){
                    return t('timecard.table.cells.shift_ended_without_schedule')
                }else{
                    return t('timecard.table.cells.working_without_schedule')
                }
            }else{
                return t('timecard.table.cells.free_day')
            }
        }
    }else{
        if(now.isBefore(cacheRange1[0])){
            return t('timecard.table.cells.waiting_shift')
        }

        if(now.isAfter(cacheRange1[1])){
            // 1-) Turno finalizado
            // 2-) Turno finalizado con excepciones
            // 3-) Ausente
            // 4-) Libre
            return t('timecard.table.cells.shift_ended')
        }
    }
}

const gettingSmartgroups = async (
    setLoading, 
    setSmartgroups, 
    setSmartgroup, 
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).employees.get_smart_groups){
        setLoading(true)

        await instance.get('smart_groups')
        .then(response => { 
            let smartgroups = response.data.map((sg) => ({ id: sg.id, name: sg.name }))
            setSmartgroups(smartgroups)
            setSmartgroup(smartgroups)
        })
        .catch(error => { 
            console.error(error.message) 
        })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'employees.employee_table.alerts.get_smart_groups.title', 
            'employees.employee_table.alerts.get_smart_groups.message',
            'error',
        )
    }
}

const gettingEmployees = async (
    setLoading, 
    smartgroups, 
    smartgroup, 
    setEmployees, 
    setEmployee, 
    addingQueryParams,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).employees.get_employees){
        setLoading(true)

        const smargroupsNames = smartgroups.filter(sg => smartgroup.map(sg => sg.name).includes(sg.name)).map(sg => sg.name)
        const promises = smartgroups.filter(sg => smartgroup.map(sg => sg.name).includes(sg.name)).map(sg => instance.get(`smart_groups/${sg.id}`))

        await Promise.all(promises)
        .then(responses => {
            const employees = []

            for(const [index, response] of responses.entries()){
                for (const id of Object.keys(response.data)){
                    employees.push({
                        id: response.data[id].person_number,
                        full_name: response.data[id].person_data.full_name,
                        smartgroup: smargroupsNames[index],
                    })
                }
            }
            
            setEmployees(employees)

            setEmployee(prev => {
                if(prev.length === 0)
                    return employees
                else{
                    return prev
                }
            })

            addingQueryParams({
                smartgroups: smartgroup.map(s => s.id),
                employees: employees.map(e => e.id),
                first: false,
            })
        })
        .catch(error => { console.error(error.message) })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'employees.employee_table.alerts.get_employees.title', 
            'employees.employee_table.alerts.get_employees.message',
            'error',
        )
    }
}

const gettingTimecard = async (
    setLoading,
    range,
    employees,
    handlerAlert,
) => {
    let rows = []

    if(JSON.parse(sessionStorage.getItem('privileges')).timecard.get_timecard){
        setLoading(true)

        const body = {
            start_date: range[0].format('YYYY-MM-DD'),
            end_date: range[1].format('YYYY-MM-DD'),
            employees: [...new Set(employees.map(employee => employee.id))],
        }

        await instance.post('timecard/multi_read', body)
        .then(response => {
            for(let person_number of Object.keys(response.data.daily_totals)){
                for(let date of Object.keys(response.data.daily_totals[person_number])){
                    let row = {
                        id: `${person_number}-${date}`,
                        person_number: person_number,
                        full_name: employees.find(employee => employee.id === person_number)?.full_name,
                        path: [`${employees.find(employee => employee.id === person_number)?.full_name} - (${employees.find(employee => employee.id === person_number)?.id})`, date],
                        date: moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                        shifts: [],
                        punch_in: response.data?.punches?.[person_number]?.[date] ? response.data?.punches?.[person_number]?.[date].filter(p => p.type === 'in').map(p => ({...p, missing: false, exception: false, hover: false})) : [],
                        punch_out: response.data?.punches?.[person_number]?.[date] ? response.data?.punches?.[person_number]?.[date].filter(p => p.type === 'out').map(p => ({...p, missing: false, exception: false, hover: false})) : [],
                        exceptions: response.data?.exceptions?.[person_number]?.[date] ? response.data?.exceptions?.[person_number]?.[date].map(e => ({...e, hover: false})) : [],
                        paycodes: [],
                        worked_hours: response.data?.daily_totals?.[person_number]?.[date] ? response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS TOTALES'] ? response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS TOTALES'].amount_in_time : '0:00' : '0:00',
                        overtime: { 
                            approved_overtime: {
                                exist: response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS APROBADAS'] ? true : false,
                                amount: response.data?.daily_totals?.[person_number]?.[date] ? response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS APROBADAS'] ? response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS APROBADAS'].amount_in_time : '0:00' : '0:00',
                            },
                            rejected_overtime: {
                                exist: response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS RECHAZADAS'] ? true : false,
                                amount: response.data?.daily_totals?.[person_number]?.[date] ? response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS RECHAZADAS'] ? response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS RECHAZADAS'].amount_in_time : '0:00' : '0:00',
                            },
                            pending_overtime: {
                                exist: response.data?.daily_totals?.[person_number]?.[date]?.totals?.['HORAS POR APROBAR'] ? true : false,
                                amount: response.data?.daily_totals?.[person_number]?.[date] ? response.data?.daily_totals?.[person_number]?.[date]?.totals?.['HORAS POR APROBAR'] ? response.data?.daily_totals?.[person_number]?.[date]?.totals?.['HORAS POR APROBAR'].amount_in_time : '0:00' : '0:00',
                            },
                        },
                        checked: false,
                        new_punch: undefined,
                        open_period: {
                            open: false,
                            end_date: undefined,
                        },
                    }

                    for(let exception of row.exceptions){
                        if(exception.in_punch_flag){
                            if(row.punch_in.find(p => p.total_span_index === exception.total_span_index)){
                                row.punch_in.find(p => p.total_span_index === exception.total_span_index).missing = false
                                row.punch_in.find(p => p.total_span_index === exception.total_span_index).exception = true
                                row.punch_in.find(p => p.total_span_index === exception.total_span_index).hover = false
                            }

                            if(exception.type === 'MISSED_PUNCH'){
                                row.punch_in.push({
                                    date,
                                    date_time: undefined,
                                    entered_on_time: undefined,
                                    time: undefined,
                                    timezone: undefined,
                                    total_span_index: exception.total_span_index,
                                    type: 'in',
                                    work_rule_name: undefined,
                                    new_punch: undefined,
                                    missing: true,
                                    exception: true,
                                    hover: false,
                                })
                            }
                        }else{
                            if(row.punch_out.find(p => p.total_span_index === exception.total_span_index)){
                                row.punch_out.find(p => p.total_span_index === exception.total_span_index).missing = false
                                row.punch_out.find(p => p.total_span_index === exception.total_span_index).exception = true
                                row.punch_out.find(p => p.total_span_index === exception.total_span_index).hover = false
                            }

                            if(exception.type === 'MISSED_OUT_PUNCH'){
                                row.punch_out.push({
                                    date,
                                    date_time: undefined,
                                    entered_on_time: undefined,
                                    time: undefined,
                                    timezone: undefined,
                                    total_span_index: exception.total_span_index,
                                    type: 'out',
                                    work_rule_name: undefined,
                                    new_punch: undefined,
                                    missing: true,
                                    exception: true,
                                    hover: false,
                                })
                            } 
                        }
                    }

                    rows.push(row)
                }
            }
        })
        .catch(error => {
            console.error(error.message)
        })
        .finally(() => {
            setLoading(false)
        })

    }else{
        handlerAlert(
            'timecard.table.alerts.get_timecard.title', 
            'timecard.table.alerts.get_timecard.message',
            'error',
        )
    }

    return rows
}

const gettingSchedule = async (
    setLoading,
    range,
    employees,
    rows,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).timecard.get_schedule){
        setLoading(true)

        const body = {
            start_date: range[0].format('YYYY-MM-DD'),
            end_date: range[1].format('YYYY-MM-DD'),
            employees: [...new Set(employees.map(employee => employee.id))]
        }

        await instance.post('schedule/multi_read', body)
        .then(response => {
            for(let person_number of Object.keys(response.data)){
                let rows_per_employee = rows.filter(row => row.person_number === person_number)

                if(rows_per_employee.length > 0){
                    for(let row of rows_per_employee){
                        if(typeof response.data[person_number]?.shifts === 'object' && response.data[person_number]?.shifts[row.date]){
                            row.shifts = response.data[person_number].shifts[row.date].map(segments => ({
                                date: row.date,
                                start: moment(segments[0].start, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm'),
                                end: moment(segments[segments.length - 1].end, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm'),
                                segments
                            }))
                        }

                        if(typeof response.data[person_number]?.hours === 'object' && response.data[person_number]?.hours[row.date]){
                            const pchs = response.data[person_number]?.hours[row.date].map(pch => ({
                                paycode: pch.paycode,
                                date: moment(pch.date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                start: pch.start,
                                amount: pch.amount, //moment.duration(pch.amount).toISOString(),
                                type: 'PCH',
                                color: 'secondary'
                            }))
                            
                            row.paycodes = [...row.paycodes, ...pchs]
                        }

                        if(typeof response.data[person_number]?.days === 'object' && response.data[person_number]?.days[row.date]){
                            const pcds = response.data[person_number]?.days[row.date].map(pcd => ({
                                paycode: pcd.paycode,
                                date: moment(pcd.date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                start: undefined,
                                amount: pcd.amount, //moment.duration(pch.amount).toISOString(),
                                type: 'PCD',
                                color: 'primary'
                            }))   
    
                            row.paycodes = [...row.paycodes, ...pcds]
                        }
                    }
                }
            }
        })
        .catch(error => { 
            console.error(error.message) 
        })
        .finally(() => { 
            setLoading(false) 
        })
    }else{
        handlerAlert(
            'timecard.table.alerts.get_schedule.title', 
            'timecard.table.alerts.get_schedule.message',
            'error',
        )
    }
    console.log('rows', rows)
    return rows
}

const gettingOpenPeriod = async (
    setLoading,
    range,
    employees,
    smartgroups,
    view,
    rows,
    setPeriods,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).timecard.get_open_period){
        setLoading(true)

        const body = {
            start_date: range[0].format('YYYY-MM-DD'),
            end_date: range[1].format('YYYY-MM-DD'),
            employees: [...new Set(employees.map(employee => employee.id))]
        }

        await instance.post('person/multi_read', body)
        .then(response => {
            const periods = []

            for(let person_number of Object.keys(response.data)){
                for(let row of rows.filter(row => row.person_number === person_number)){
                    row.open_period = {
                        open: moment(response?.data?.[person_number]?.person_data?.mgr_sign_off_thru_datetime, ['YYYY-MM-DD', 'DD/MM/YYYY']).isBefore(moment(row.date, ['YYYY-MM-DD', 'DD/MM/YYYY'])),
                        end_date: moment(response?.data?.[person_number]?.person_data?.mgr_sign_off_thru_datetime, ['YYYY-MM-DD', 'DD/MM/YYYY']).format('YYYY-MM-DD'),
                    }
                }

                periods.push({
                    full_name: `${response?.data?.[person_number]?.person_data?.full_name} - (${person_number})`,
                    person_number,
                    end_date: moment(response?.data?.[person_number]?.person_data?.mgr_sign_off_thru_datetime, ['YYYY-MM-DD', 'DD/MM/YYYY']).format('YYYY-MM-DD'),
                })
            }
            
            setPeriods(periods)

            saveReload(
                view, 
                range[0].format('YYYY-MM-DD'), 
                range[1].format('YYYY-MM-DD'), 
                [...new Set(smartgroups.map(smartgroup => smartgroup.id))],
                employees, 
            )
        })
        .catch(error => {
            console.error(error.message)
        })
        .finally(() => { 
            const data = JSON.parse(sessionStorage.getItem(view))
    
            if(data){                
                sessionStorage.setItem(view, JSON.stringify({ 
                    ...data,
                    environment: data?.environment ? data.environment : null,
                    redirect: null
                }))
            }

            setLoading(false)
        })
    }else{
        handlerAlert(
            'timecard.table.alerts.get_open_period.title', 
            'timecard.table.alerts.get_open_period.message',
            'error',
        )
    }

    return rows
}

const deletingPunch = async (
    setLoading,
    date,
    time,
    person_number,
    reloading,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).timecard.delete_punch){
        setLoading(true)

        console.log('data', {
            date,
            time,
            person_number,
        })

        await instance.delete(`timecard/punch/${person_number}/${date}/${time}`)
        .then(() => {
            reloading()
        })
        .catch(error => { 
            console.error(error.message) 
        })
        .finally(() => { 
            setLoading(false) 
        })
    }else{
        handlerAlert(
            'timecard.table.alerts.delete_punch.title', 
            'timecard.table.alerts.delete_punch.message',
            'error',
        )
    }
}

const deletingPaycode = async (
    setLoading,
    person_number,
    date,
    paycode,
    reloading,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).timecard.delete_paycode){
        setLoading(true)

        /* if(paycode.type === 'PCT'){
            console.log('data PCT', {
                person_number,
                date,
                paycode: paycode.paycode,
                amount: paycode.amount,
                start_time: paycode.start,
            })

            await instance.delete(`timecard/paycode/${person_number}/${date}`, {
                params: {
                    paycode: paycode.paycode,
                    amount: paycode.amount,
                    start_time: paycode.start,
                }
            })
            .then(() => {
                reloading()
            })
            .catch(error => { 
                console.error(error.message) 
            })
            .finally(() => { 
                setLoading(false) 
            })
        } */
        if (paycode.type === 'PCH'){
            await instance.post(`schedule/paycodes/hours/delete`, {
                person_number,
                paycode: paycode.paycode,
                date: paycode.date,
                start_time: moment(paycode.start, ['H:mm', 'HH:mm']).format('HH:mm'),
                amount: moment(paycode.amount, ['H:mm', 'HH:mm']).format('HH:mm'),
            })
            .then(() => {
                reloading()
            })
            .catch(error => { 
                console.error(error.message) 
            })
            .finally(() => { 
                setLoading(false) 
            })
        }else{
            await instance.post(`schedule/paycodes/days/delete`, {
                person_number,
                paycode: paycode.paycode,
                start_date: paycode.date,
                end_date: paycode.date,
            })
            .then(() => {
                reloading()
            })
            .catch(error => { 
                console.error(error.message) 
            })
            .finally(() => { 
                setLoading(false) 
            })
        
        }
    }else{
        handlerAlert(
            'timecard.table.alerts.delete_paycode.title', 
            'timecard.table.alerts.delete_paycode.message',
            'error',
        )
    }
}

const addingPunch = async (
    setLoading,
    punch,
    handlerAlert,
    reloading,
    closing,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).timecard.add_punch){
        setLoading(true)

        const body = {
            data: [
                punch
            ]
        }

        await instance.post('timecard/punch', body)
        .then(response => { 
            reloading()
            closing()
        })
        .catch(error => { 
            console.error(error.message) 
        })
        .finally(() => { 
            setLoading(false) 
        })
    }else{
        handlerAlert(
            'timecard.table.alerts.add_punch.title', 
            'timecard.table.alerts.add_punch.message',
            'error',
        )
    }
}

const addingHourPaycode = async (
    setLoading,
    employees,
    range,
    paycode,
    start_time,
    amount,
    override,
    files,
    closing,
    reloading,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).timecard.add_paycode){
        setLoading(true)

        const promises = employees.map((person_number) => {
            let data = new FormData()
            
            for(const [index, date] of daysBetween(range[0], range[1]).entries()){
                data.append(`paycodes[${index}][paycode]`, paycode)
                data.append(`paycodes[${index}][person_number]`, person_number)
                data.append(`paycodes[${index}][start_time]`, start_time.format('HH:mm'))
                data.append(`paycodes[${index}][date]`, date)
                data.append(`paycodes[${index}][amount]`, amount.hours() + (amount.minutes() / 60))
                data.append(`paycodes[${index}][override]`, override)
                for(const file of files){
                    data.append(`paycodes[${index}][file]`, new Blob([file], { type: file.type }), file.name || 'file')
                }
            }

            return instance.post('schedule/paycodes/hours', data)
        })

        console.log('promises', promises)

        await Promise.all(promises)
        .then(() => {
            reloading()
            closing()
        })
        .catch((error) => { console.error(error.message) })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'schedule.table.alerts.add_paycode_hours.title', 
            'schedule.table.alerts.add_paycode_hours.message',
            'error',
        )
    }
}

export {
    gettingTotalOvertime,
    gettingTotalWorkedHours,
    gettingShiftStatus,
    gettingSmartgroups,
    gettingEmployees,
    gettingTimecard,
    gettingSchedule,
    gettingOpenPeriod,
    deletingPunch,
    deletingPaycode,
    addingPunch,
    addingHourPaycode,
}