import moment from 'moment';
import {
    createContext,
    useContext,
    useState,
} from 'react';
import { getWeek } from '../../../utils/Dates';
import { /* gettingSchedule, */ gettingOpenPeriod, gettingSchedule, gettingTimecard } from '../../../utils/Timecard';
import { gettingAudit } from '../../../utils/Audits';
import { GlobalContext } from '../../../GlobalContext';
import { useGridApiRef } from '@mui/x-data-grid';

const Context = createContext({});

const ContextProvider = ({ children, value }) => {
    const { handlerAlert } = useContext(GlobalContext)

    const apiRef = useGridApiRef();

    const [range1, setRange1] = useState([
        moment(getWeek()[0]), 
        moment(getWeek()[1])
    ]);

    const [cacheRange1, setCacheRange1] = useState([
        moment(getWeek()[0]), 
        moment(getWeek()[1])
    ]);

    const [range2, setRange2] = useState([
        moment(getWeek()[0]), 
        moment(getWeek()[1])
    ])

    const [range3, setRange3] = useState([
        moment(getWeek()[0]), 
        moment(getWeek()[1])
    ])

    const [smartgroups, setSmartgroups] = useState([]);
    const [smartgroup, setSmartgroup] = useState([]);
    const [filter1, setFilter1] = useState('');

    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [filter2, setFilter2] = useState('');

    const [rows, setRows] = useState([]);
    const [checked, setChecked] = useState([]);
    const [periods, setPeriods] = useState([]);

    const [audit, setAudit] = useState([]);
    const [search, setSearch] = useState('audit_date');
    

    const [ready, setReady] = useState(false)
    const [renders, setRenders] = useState(0)

    const [loadingGettingSmartgroups, setLoadingGettingSmartgroups] = useState(false);
    const [loadingGettingEmployees, setLoadingGettingEmployees] = useState(false);
    const [loadingGettingTimecard, setLoadingGettingTimecard] = useState(false);
    const [loadingGettingSchedule, setLoadingGettingSchedule] = useState(false);
    const [loadingGettingAudit, setLoadingGettingAudit] = useState(false);
    const [loadingGettingOpenPeriod, setLoadingGettingOpenPeriod] = useState(false);

    // Modals
    const [row, setRow] = useState({});
    const [openAddPunch, setOpenAddPunch] = useState(false);
    const [openAddPaycode, setOpenAddPaycode] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [openRecant, setOpenRecant] = useState(false);
    const [openConfirmAddPunch, setOpenConfirmAddPunch] = useState(false);
    const [addOpenPaycode, setAddOpenPaycode] = useState(false);
    const [action, setAction] = useState('timecard');

    const [amount, setAmount] = useState(moment('00:00', 'HH:mm'));

    const searching = async () => {         
        setCacheRange1(range1)
        setRange3(range1)
        
        let result_timecard    = await gettingTimecard(setLoadingGettingTimecard, range1, employee, handlerAlert)
        let result_schedule    = await gettingSchedule(setLoadingGettingSchedule, range1, employee, result_timecard, handlerAlert)
        let result_open_period = await gettingOpenPeriod(setLoadingGettingOpenPeriod, range1, employee, smartgroup, 'timecard', result_schedule, setPeriods, handlerAlert)

        setRows(
            result_open_period
            .filter(
                row =>  
                    moment(row.date, 'YYYY-MM-DD').isSameOrAfter(range1[0]) && 
                    moment(row.date, 'YYYY-MM-DD').isSameOrBefore(range1[1])
            ).sort(
                (a, b) => new Date(a.date) - new Date(b.date)
            )
        )
    
        await gettingAudit(
            setLoadingGettingAudit,
            JSON.parse(sessionStorage.getItem('account'))?.id,
            range2,
            search,
            'timecard',
            setAudit,
            handlerAlert,
        )

        //
    }

    const reloading = async () => { 
        const data = JSON.parse(sessionStorage.getItem('timecard'))

        setCacheRange1(range1)
        setRange3(range1)
        
        let result_timecard = await gettingTimecard(
            setLoadingGettingTimecard, 
            [moment(data?.reloading?.start, 'YYYY-MM-DD'), moment(data?.reloading?.end, 'YYYY-MM-DD')], 
            data?.reloading?.employees, 
            handlerAlert
        )
        
        let result_schedule = await gettingSchedule(
            setLoadingGettingSchedule, 
            [moment(data?.reloading?.start, 'YYYY-MM-DD'), moment(data?.reloading?.end, 'YYYY-MM-DD')], 
            data?.reloading?.employees,  
            result_timecard, 
            handlerAlert
        )

        let result_open_period = await gettingOpenPeriod(
            setLoadingGettingOpenPeriod, 
            [moment(data?.reloading?.start, 'YYYY-MM-DD'), moment(data?.reloading?.end, 'YYYY-MM-DD')], 
            data?.reloading?.employees,
            data?.reloading?.smartgroups,
            'timecard',
            result_schedule, 
            setPeriods, 
            handlerAlert
        )
        
        setRows(
            result_open_period
            .filter(
                row =>  
                    moment(row.date, 'YYYY-MM-DD').isSameOrAfter(range1[0]) && 
                    moment(row.date, 'YYYY-MM-DD').isSameOrBefore(range1[1])
            ).sort(
                (a, b) => new Date(a.date) - new Date(b.date)
            )
        )
    
        await gettingAudit(
            setLoadingGettingAudit,
            JSON.parse(sessionStorage.getItem('account'))?.id,
            range2,
            search,
            'timecard',
            setAudit,
            handlerAlert,
        )
    }

    return(
        <Context.Provider 
            value={{
                rows, setRows,
                range1, setRange1,
                cacheRange1, setCacheRange1,
                range2, setRange2,
                range3, setRange3,
                smartgroups, setSmartgroups,
                smartgroup, setSmartgroup,
                filter1, setFilter1,
                employees, setEmployees,
                employee, setEmployee,
                periods, setPeriods,
                filter2, setFilter2,
                loadingGettingSmartgroups, setLoadingGettingSmartgroups,
                loadingGettingEmployees, setLoadingGettingEmployees,
                loadingGettingTimecard, setLoadingGettingTimecard,
                loadingGettingSchedule, setLoadingGettingSchedule,
                loadingGettingAudit, setLoadingGettingAudit,
                loadingGettingOpenPeriod, setLoadingGettingOpenPeriod,
                audit, setAudit,
                search, setSearch,
                ready, setReady,
                renders, setRenders,
                checked, setChecked,
                row, setRow,
                amount, setAmount,
                openAddPunch, setOpenAddPunch,
                openAddPaycode, setOpenAddPaycode,
                openConfirmAddPunch, setOpenConfirmAddPunch,
                openApprove, setOpenApprove,
                openRecant, setOpenRecant,
                addOpenPaycode, setAddOpenPaycode,
                action, setAction,
                apiRef,
                reloading,
                searching,
                ...value,
            }}
        >
            {children}
        </Context.Provider>
    )
};

export {
    Context,
    ContextProvider,
}
