import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { Alert, Avatar, Box, Button, Collapse, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, Tab, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import KeyIcon from '@mui/icons-material/Key';
import WindowIcon from '@mui/icons-material/Window';
import GoogleIcon from '@mui/icons-material/Google';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Options } from '../../menu/settings/Options';
import { getBorder } from '../../../utils/Styles';
import { changingPassword } from '../../../utils/Settings';
import { GlobalContext } from '../../../GlobalContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';

const Config = ({open, setOpen}) => {
    const { 
        handlerAlert,
        setTheme,
        theme,
    } = useContext(GlobalContext)

    const { t, i18n } = useTranslation()
    
    const [value, setValue] = useState('1');

    const [openChangePassword, setOpenChangePassword] = useState(true)
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [visibility1, setVisibility1] = useState(false)
    const [visibility2, setVisibility2] = useState(false)
    const [visibility3, setVisibility3] = useState(false)

    const [loadingChangePassword, setLoadingChangePassword] = useState(false)
    const [errorChangePassword, setErrorChangePassword] = useState(false)

    const options = useMemo(() => [
        {
            id: 1,
            text: openChangePassword ? t('settings.tab.tab1.list.list1.options.hide_password_change') : t('settings.tab.tab1.list.list1.options.password_change'),
            icon: <KeyIcon />,
            click: () => {
                setOpenChangePassword(!openChangePassword)

                if(openChangePassword === false){
                    setPassword('')
                    setNewPassword('')
                    setConfirmPassword('')
                }
            },
            disable: JSON.parse(sessionStorage.getItem('privileges')).settings.change_password
        },
        {
            id: 2,
            text: t('settings.tab.tab1.list.list2.options.unlink'),
            icon: <DeleteIcon />,
            click: () => {},
            disable: false
        },
        {
            id: 3,
            text: t('settings.tab.tab1.list.list2.options.update'),
            icon: <EditIcon />,
            click: () => {},
            disable: false
        }
    ], [openChangePassword, t])

    const closing = () => {
        setOpen(false)
        setPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setLoadingChangePassword(false)
        setErrorChangePassword(false)
        setOpenChangePassword(true)
    }

    useEffect(() => {
    }, [])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => closing()}
                fullWidth
            >
                <DialogTitle>
                    <Box 
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Typography variant='body3'>{ t('settings.title') }</Typography>

                        <IconButton 
                            sx={{marginLeft: 'auto', marginRight: -1}}
                            onClick={() => closing()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{pl: 0, pr: 0, pt: 0}}>
                    <Box 
                        sx={{ 
                            width: '100%', 
                            typography: 'body1',
                        }}
                    >
                        <TabContext sx={{width: '100%'}} value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={(event, nv) => setValue(nv)}>
                                    <Tab label={t('settings.tab.tab1.title')} value="1" />
                                    {/* <Tab label={t('settings.tab.tab2.title')} value="2" /> */}
                                    { (JSON.parse(sessionStorage.getItem('privileges'))?.settings?.change_language || JSON.parse(sessionStorage.getItem('privileges'))?.settings?.change_theme) && <Tab label={t('settings.tab.tab3.title')} value="3" /> }
                                </TabList>
                            </Box>

                            <TabPanel sx={{height: '500px'}} value="1">
                                <List>
                                    { JSON.parse(sessionStorage.getItem('privileges'))?.settings?.change_password &&
                                        <>
                                            <ListItem secondaryAction={options.filter(opt => opt.disable === false).length > 0 ? <Options options={options.filter(opt => opt.id === 1)} /> : <></>}>
                                                <ListItemIcon sx={{ml: -0.5}}>
                                                    <Avatar>
                                                        <KeyIcon />
                                                    </Avatar>
                                                </ListItemIcon>

                                                <ListItemText 
                                                    primary={t('settings.tab.tab1.list.list1.title')} 
                                                    secondary={
                                                        t('settings.tab.tab1.list.list1.subtitle', {
                                                            username: JSON.parse(sessionStorage.getItem('account'))?.username, 
                                                            password: '* * * * * *'
                                                        })
                                                    }
                                                />
                                            </ListItem>

                                            <Collapse in={openChangePassword} timeout="auto" unmountOnExit>
                                                <Box 
                                                    sx={{
                                                        m: 1,
                                                        border: theme => getBorder(theme.palette.mode),
                                                        borderRadius: 2,
                                                        p: 2.5
                                                    }}
                                                >
                                                    <Typography variant='body3'>
                                                        <b>{ t('settings.tab.tab1.list.list1.form.title') }</b>
                                                    </Typography>

                                                    <Grid container>
                                                        <Alert 
                                                            variant='filled'
                                                            severity="warning"
                                                            sx={{width: '100%', mt: 2.5}}
                                                        >
                                                            {t('settings.tab.tab1.list.list1.form.alerts.signout.title')}
                                                        </Alert>

                                                        <Grid item xs={12} sx={{mb: 2.5, mt: 3.5}}>
                                                            <FormControl fullWidth>
                                                                <InputLabel htmlFor='password_label'>{t('settings.tab.tab1.list.list1.form.password')}</InputLabel>

                                                                <OutlinedInput
                                                                    id='password_label'
                                                                    label={t('settings.tab.tab1.list.list1.form.password')}
                                                                    variant="outlined" 
                                                                    value={password}
                                                                    onChange={(e) => setPassword(e.target.value)}
                                                                    placeholder='* * * * * * * *'
                                                                    fullWidth
                                                                    type={visibility1 ? 'text' : 'password'}
                                                                    error={errorChangePassword}
                                                                    autoComplete="new-password"
                                                                    endAdornment={
                                                                        <InputAdornment position="end" >
                                                                            { visibility1 ? 
                                                                                <VisibilityOffIcon sx={{cursor: 'pointer'}} onClick={() => setVisibility1(false)}/> 
                                                                                :
                                                                                <VisibilityIcon sx={{cursor: 'pointer'}} onClick={() => setVisibility1(true)}/>
                                                                            }
                                                                        </InputAdornment>
                                                                    }
                                                                />

                                                                <FormHelperText error={errorChangePassword} id="password_label">{errorChangePassword ? 'La contraseña es incorrecta' : ''}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12} sx={{mb: 2.5}}>
                                                            <FormControl fullWidth>
                                                                <InputLabel htmlFor='new_password_label'>{t('settings.tab.tab1.list.list1.form.new_password')}</InputLabel>

                                                                <OutlinedInput
                                                                    id='new_password_label'
                                                                    label={t('settings.tab.tab1.list.list1.form.new_password')}
                                                                    variant="outlined" 
                                                                    value={newPassword}
                                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                                    placeholder='* * * * * * * *'
                                                                    fullWidth
                                                                    type={visibility2 ? 'text' : 'password'}
                                                                    error={newPassword !== confirmPassword}
                                                                    autoComplete="new-password"
                                                                    endAdornment={
                                                                        <InputAdornment position="end" >
                                                                            { visibility2 ? 
                                                                                <VisibilityOffIcon sx={{cursor: 'pointer'}} onClick={() => setVisibility2(false)}/> 
                                                                                :
                                                                                <VisibilityIcon sx={{cursor: 'pointer'}} onClick={() => setVisibility2(true)}/>
                                                                            }
                                                                        </InputAdornment>
                                                                    }
                                                                />

                                                                <FormHelperText error={newPassword !== confirmPassword} id="new_password_label">{newPassword !== confirmPassword ? 'La contraseña no coincide' : ''}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12} sx={{mb: 2.5}}>
                                                            <FormControl fullWidth>
                                                                <InputLabel htmlFor='confirm_password_label'>{t('settings.tab.tab1.list.list1.form.confirm_password')}</InputLabel>

                                                                <OutlinedInput
                                                                    id='confirm_password_label'
                                                                    label={t('settings.tab.tab1.list.list1.form.confirm_password')}
                                                                    variant="outlined" 
                                                                    value={confirmPassword}
                                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                                    placeholder='* * * * * * * *'
                                                                    fullWidth
                                                                    type={visibility3 ? 'text' : 'password'}
                                                                    error={newPassword !== confirmPassword}
                                                                    autoComplete="new-password"
                                                                    endAdornment={
                                                                        <InputAdornment position="end" >
                                                                            { visibility3 ? 
                                                                                <VisibilityOffIcon sx={{cursor: 'pointer'}} onClick={() => setVisibility3(false)}/> 
                                                                                :
                                                                                <VisibilityIcon sx={{cursor: 'pointer'}} onClick={() => setVisibility3(true)}/>
                                                                            }
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                                <FormHelperText error={newPassword !== confirmPassword} id="confirm_password_label">{newPassword !== confirmPassword ? 'La contraseña no coincide' : ''}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid container direction={'row'} sx={{justifyContent: 'flex-end'}}>
                                                            <LoadingButton
                                                                variant='contained'
                                                                disableElevation 
                                                                sx={{mr: 1.5}}
                                                                onClick={() => {
                                                                    changingPassword(
                                                                        setLoadingChangePassword, 
                                                                        setErrorChangePassword, 
                                                                        setPassword,
                                                                        setNewPassword,
                                                                        setConfirmPassword,
                                                                        password, 
                                                                        newPassword, 
                                                                        handlerAlert
                                                                    )
                                                                }}
                                                                color='primary'
                                                                loading={loadingChangePassword}
                                                                disabled={
                                                                    password === '' || 
                                                                    newPassword === '' || 
                                                                    confirmPassword === '' || 
                                                                    newPassword !== confirmPassword
                                                                }
                                                            >
                                                                {t('settings.tab.tab1.list.list1.form.buttons.change')}
                                                            </LoadingButton>

                                                            <Button 
                                                                variant='contained'
                                                                color='error'
                                                                disableElevation
                                                                onClick={() => {
                                                                    setOpenChangePassword(false)
                                                                    setPassword('')
                                                                    setNewPassword('')
                                                                    setConfirmPassword('')
                                                                }}
                                                            >
                                                                {t('settings.tab.tab1.list.list1.form.buttons.cancel')}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Collapse>
                                        </>
                                    }

                                    { JSON.parse(sessionStorage.getItem('privileges'))?.settings?.sso_microsoft &&
                                        <ListItem secondaryAction={options.filter(opt => opt.disable === false).length > 0 ? <Options options={options.filter(opt => opt.id !== 1)}/> : <></>}>
                                            <ListItemIcon sx={{ml: -0.5}}>
                                                <Avatar>
                                                    <WindowIcon />
                                                </Avatar>
                                            </ListItemIcon>

                                            <ListItemText 
                                                primary={t('settings.tab.tab1.list.list2.title')} 
                                                secondary={JSON.parse(sessionStorage.getItem('account'))?.email}
                                            />
                                        </ListItem>
                                    }
                                    
                                    { JSON.parse(sessionStorage.getItem('privileges'))?.settings?.sso_google &&
                                        <ListItem secondaryAction={options.filter(opt => opt.disable === false).length > 0 ? <Options options={options.filter(opt => opt.id !== 1)}/> : <></>}>
                                            <ListItemIcon sx={{ml: -0.5}}>
                                                <Avatar>
                                                    <GoogleIcon />
                                                </Avatar>
                                            </ListItemIcon>

                                            <ListItemText 
                                                primary={t('settings.tab.tab1.list.list3.title')}
                                                secondary={'nicolas.rivera@scmlatam.com'}
                                            />
                                        </ListItem>
                                    }
                                </List>
                            </TabPanel>

                            <TabPanel sx={{height: '500px'}} value="2">

                            </TabPanel>
                            
                            <TabPanel sx={{height: '500px'}} value="3">
                                <Grid container spacing={2}>
                                    { JSON.parse(sessionStorage.getItem('privileges'))?.settings?.change_language &&
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="l-language">{t('settings.tab.tab3.language.title')}</InputLabel>

                                                <Select
                                                    labelId="l-language"
                                                    id="language"
                                                    value={i18n.language}
                                                    label={t('settings.tab.tab3.language.title')}
                                                    onChange={(event) => {
                                                        i18n.changeLanguage(event.target.value)
                                                    }}
                                                >
                                                    <MenuItem value={'en'}>{t('settings.tab.tab3.language.english')}</MenuItem>
                                                    <MenuItem value={'es'}>{t('settings.tab.tab3.language.spanish')}</MenuItem>
                                                    <MenuItem value={'pe'}>{t('settings.tab.tab3.language.spanish-peru')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                    
                                    { JSON.parse(sessionStorage.getItem('privileges'))?.settings?.change_theme &&
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="l-theme">{t('settings.tab.tab3.theme')}</InputLabel>

                                                <Select
                                                    labelId="l-theme"
                                                    id="theme"
                                                    value={theme}
                                                    label={t('settings.tab.tab3.theme')}
                                                    onChange={(event) => {
                                                        setTheme(event.target.value)
                                                    }}
                                                >
                                                    <MenuItem value={'light'}>Light</MenuItem>
                                                    <MenuItem value={'dark'}>Dark</MenuItem>
                                                    <MenuItem value={'rabbitLight'}>Rabbit Light</MenuItem>
                                                    <MenuItem value={'rabbitDark'}>Rabbit Dark</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                </Grid>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </DialogContent>

                {/* <DialogActions>
                </DialogActions> */}
            </Dialog>
        </>
    )
}

export { 
    Config 
}