import React, { useCallback, useEffect, useState } from 'react'
import { WebPaths } from './routes/Paths'
import { GlobalContext } from './GlobalContext'
import { Alert, AlertTitle, CssBaseline, Snackbar, ThemeProvider } from '@mui/material'
import { 
	RabbitLight,
	RabbitDark,
	LightTheme, 
	DarkTheme
} from './styles/themes'
import { useTranslation } from 'react-i18next'
import { MsalProvider } from '@azure/msal-react'
import { applyKeyMUI } from './utils/mui'
import moment from 'moment'
import "moment/locale/es";
import "moment/locale/en-gb";

const Theme = {
	light: LightTheme,
	dark: DarkTheme,
	rabbitLight: RabbitLight,
	rabbitDark: RabbitDark,
}

const App = ({instance}) => {
	const { t, i18n } = useTranslation()

	const [authenticated, setAuthenticated] = useState(false)
	const [openLeft, setOpenLeft]           = useState(sessionStorage.getItem('openLeft') === 'true' ? true : false)
	const [openRight, setOpenRight]         = useState(sessionStorage.getItem(`right-${window.location.pathname}`) === 'true' ? true : false)
	const [openAlert, setOpenAlert]         = useState(false)
	const [theme, setTheme]                 = useState('light')

	const [alert, setAlert] 			    = useState({
		title: 'Alerta', 
		message: 'mensaje de error', 
		severity: 'error', 
	})

	const handlerSideLeft = (bool) => {        
		sessionStorage.setItem('openLeft', bool)
		setOpenLeft(bool)
	}

	const handlerSideRight = (bool) =>{
		sessionStorage.setItem(`right-${window.location.pathname}`, bool)
        setOpenRight(bool)
    }
	
	const handlerAlert = useCallback((title, message, severity) => {
		setAlert({
			title, 
			message, 
			severity
		})

		setOpenAlert(true)
	}, [])

	useEffect(() => {
		document.title = `${process.env.REACT_APP_PRODUCT_NAME}`	
		
		applyKeyMUI()

		setAuthenticated(sessionStorage.getItem('token'))
		setOpenLeft(sessionStorage.getItem('openLeft') === 'true' ? true : false)
		setOpenRight(sessionStorage.getItem(`right-${window.location.pathname}`) === 'true' ? true : false)
		setTheme(sessionStorage.getItem('config')?.theme || 'light')

		switch(i18n.language){
			case 'pe':
				moment.locale('es')
				break
			default:
				moment.locale(i18n.language)
		}

	}, [i18n.language])
	
	return (
		<>
			<MsalProvider instance={instance}>
				<GlobalContext.Provider 
					value={{
						authenticated, 
						setAuthenticated,
						openLeft,
						handlerSideLeft,
						openRight,
						handlerSideRight,
						handlerAlert,
						setTheme,
						theme
					}}
				>

					<ThemeProvider theme={Theme[theme]}>
						<CssBaseline />

						{/* 
							<Box sx={{display: {xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block'}}}>
							</Box> 
						*/}

						<WebPaths />

						{/* 
							<Box sx={{display: {xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none'}}}>
								<MobilePaths />
							</Box> 
						*/}
					</ThemeProvider>

					<Snackbar 
						open={openAlert}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						severity={alert.severity}
						//onClose={() => { setOpenAlert(false) }}
					>
						<Alert variant="filled" severity={alert.severity} onClose={() => {setOpenAlert(false)}}>
							{ alert.title ? <AlertTitle>{t(alert.title)}</AlertTitle> : null }

							{ t(alert.message) }
						</Alert>

					</Snackbar>
				</GlobalContext.Provider>
			</MsalProvider>
		</>
	)
}

export default App