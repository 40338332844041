import { Autocomplete, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import { DateField, TimeField } from '@mui/x-date-pickers'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { LoadingButton } from '@mui/lab';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Context } from '../../table/timecard/Context';
import { applyingOvertime } from '../../../utils/Overtime';
import { GlobalContext } from '../../../GlobalContext';

const ApproveOvertime = ({open, setOpen, data}) => {
    const { t } = useTranslation()

    const {
        amount,
        setAmount,
        reloading,
    } = useContext(Context)

    const {
        handlerAlert,
    } = useContext(GlobalContext)

    const [date, setDate] = useState(null)
    const [partial, setPartial] = useState(null)
    const [action, setAction] = useState('none')
    const [names, setNames] = useState(data.map(e => e.name))

    const [loading, setLoading] = useState(false)

    const closing = () => {
        setOpen(false)
        setDate(null)
        setPartial(null)
        setAction('none')
        setNames([])
        setLoading(false)
    }

    useEffect(() => {
        if(open){
            setDate(amount)
            setPartial(amount.format('HH:mm'))
            setAction('none')
            setNames(data.map(e => e.name))
        }
    }, [amount, data, open])

    return (
        <>
            <Dialog
                fullWidth
                open={open} 
                onClose={() => { closing() }}
            >
                <DialogTitle
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Typography variant='body3'>{ t('timecard.table.modals.manage_overtime.title') }</Typography>
                </DialogTitle>

                <DialogContent
                    dividers
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                limitTags={5}
                                options={useMemo(() => data.map(employee => employee.name), [data])}
                                value={names}
                                onChange={(event, newValue) => { 
                                    setNames(newValue) 
                                    console.log(newValue)
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        { option }
                                    </li>
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={index}
                                            label={option}
                                            //color={moment(periods.find(p => p?.person_number === data.find(d => d.name === option)?.id)?.end_date, 'YYYY-MM-DD').isSameOrAfter(range[0]) ? 'warning' : undefined} // Utiliza el color personalizado
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label={t('schedule.table.modals.add_paycode.employees')}
                                    />
                                }
                                disabled
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DateField
                                label={t('timecard.table.modals.manage_overtime.date')}
                                value={date}
                                disabled
                                onChange={(value) => setDate(value)}
                                sx={{ width: '100%' }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TimeField
                                label={t('timecard.table.modals.manage_overtime.total_amount_of_hours')}
                                value={amount}
                                disabled
                                onChange={(newValue) => setAmount(newValue)}
                                sx={{ width: '100%' }}
                                ampm={false}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="laction">{t('timecard.table.modals.manage_overtime.action.title')}</InputLabel>
                                    
                                <Select
                                    labelId="laction"
                                    id="action"
                                    value={action}
                                    label={t('timecard.table.modals.manage_overtime.action.title')}
                                    onChange={(event) => { 
                                        setAction(event.target.value)
                                    }}
                                >
                                    <MenuItem value={'all'}>{t('timecard.table.modals.manage_overtime.action.full_approval')}</MenuItem>
                                    <MenuItem value={'some'}>{t('timecard.table.modals.manage_overtime.action.partial_approval')}</MenuItem>
                                    <MenuItem value={'none'}>{t('timecard.table.modals.manage_overtime.action.reject_everything')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label={t('timecard.table.modals.manage_overtime.partial_amount_of_hours_to_approve')}
                                value={partial}
                                onChange={(event) => {
                                    setPartial(event.target.value)
                                }}
                                sx={{ width: '100%' }}
                                disabled={action !== 'some'}
                                placeholder={t('overtime.table.rows.partial.placeholder')}
                                error={!/^\d{1,2}:[0-5]\d$/.test(partial)}
                                helperText={!/^\d{1,2}:[0-5]\d$/.test(partial) ? t('overtime.table.rows.partial.error') : ""}
                                InputProps={{
                                    endAdornment: 
                                        <InputAdornment position="end">
                                            <WatchLaterIcon />
                                        </InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Tooltip title={
                        !/^\d{1,2}:[0-5]\d$/.test(partial)
                        ?
                        <>
                            <List dense={true}>
                                { !/^\d{1,2}:[0-5]\d$/.test(partial) &&
                                    <ListItem>
                                        <ListItemText primary={t('timecard.table.modals.manage_overtime.buttons.apply.format_is_invalid_for_partial_mount')}/> 
                                    </ListItem>
                                }
                            </List>
                        </>
                        :
                        ''
                    }>
                        <span>
                            <LoadingButton
                                variant='contained'
                                disableElevation 
                                onClick={() => {
                                    const overtimes = [{
                                        person_number: data[0].id,
                                        date: date.format('YYYY-MM-DD'),
                                        action,
                                        partial_amount: partial,
                                        comment: undefined,
                                        notes: undefined,
                                    }]

                                    console.log(overtimes)

                                    applyingOvertime(
                                        setLoading,
                                        overtimes,
                                        closing,
                                        reloading,
                                        handlerAlert,
                                    )
                                }}
                                color='primary'
                                loading={loading}
                                disabled={!/^\d{1,2}:[0-5]\d$/.test(partial)}
                            >
                                {t('timecard.table.modals.manage_overtime.buttons.apply.title')}
                            </LoadingButton>
                        </span>
                    </Tooltip>

                    <Button 
                        variant='contained' 
                        disableElevation 
                        color='error' 
                        onClick={() => { closing() }}
                        sx={{mr: 1.5}}
                    >
                        {t('timecard.table.modals.manage_overtime.buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export {
    ApproveOvertime
}