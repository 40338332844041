import { GoogleGenerativeAI } from "@google/generative-ai";

const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GOOGLE_GEMINI_API_KEY);

const initChat = () => {
    const model = genAI.getGenerativeModel({ model: "gemini-2.0-flash" });

    const chat = model.startChat({
        history: [
            /* {
                role: "user",
                parts: [{ text: "Gemini una responde cuanto es 2+1" }],
            }, */
            /* {
                role: "model",
                parts: [{ text: "Great to meet you. What would you like to know?" }],
            }, */
        ],
        generationConfig: {
            maxOutputTokens: 1000,
        },
    });

    console.log('init complete');

    return {
        model,
        chat,
    }
}

const sendMessage = async (
    setLoading, 
    chat, 
    setChat,
    msg,
) => {
    setLoading(true);

    await chat.sendMessage(msg)
    .then((response) => {
        return response.response.text();
    })
    .catch((error) => {
        console.error(error);
    })
    .finally(() => {
        setLoading(false);
        setChat(chat);
    });
}

/* const msg = "puedes responder en español?";

await chat.sendMessage(msg)
.then((response) => {
    console.log('response', response.response.text());
})
.catch((error) => {
    console.error(error);
})
.finally(() => {
    console.log("Done");
    console.log("chat", chat);
    setChat(chat);
}); */

export { 
    initChat,
    sendMessage, 
}