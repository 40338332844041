import { LoadingButton } from "@mui/lab"
import { Alert, Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from "@mui/material"
import { memo, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import Spreadsheet from "react-spreadsheet"
import { getBorder } from "../../../utils/Styles"
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import InfoIcon from '@mui/icons-material/Info';
import moment from "moment"
import * as XLSX from "xlsx";
import { bulkLoading } from "../../../utils/Schedule"
import { GlobalContext } from "../../../GlobalContext"



const checkingRows = (rows, setError, setVerified, setOpenAlert, setLoadingChecking) => {
    setLoadingChecking(true)
    setVerified(true)

    for(let row of rows){
        if(
            !moment(row[1]?.value, 'YYYY-MM-DD HH:mm').isValid() || 
            !['regular', 'break'].find(t => t === row[2]?.value?.toLowerCase()) || 
            !moment(row[3]?.value, 'YYYY-MM-DD HH:mm').isValid() || 
            !moment(row[4]?.value, 'YYYY-MM-DD HH:mm').isValid()
        ){
            setError(true)
            setLoadingChecking(false)

            setOpenAlert(true)
            return false
        }
    }

    setError(false)
    setLoadingChecking(false)

    setOpenAlert(true)
    return true
}

const Cell = memo(({ data }) => {
    //console.log('cell:', cell)
    const gettingStyle = (validation) => {
        return {
            p: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: validation
        }
    }

    switch(data?.column) {
        // DATE
        case 1: 
            return (
                <Box sx={gettingStyle(moment(data?.cell?.value, 'YYYY-MM-DD HH:mm').isValid() ? theme => theme.palette.text.primary : theme => theme.palette.error.main)}> 
                    { moment(data?.cell?.value, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') }
                </Box>
            )
        // TYPE
        case 2:
            return (
                <Box sx={gettingStyle(['regular', 'break'].find(t => t === data?.cell?.value?.toLowerCase()) ? theme => theme.palette.text.primary : theme => theme.palette.error.main)}> 
                    { data?.cell?.value }
                </Box>
            )
        // START TIME
        case 3:
            return (
                <Box sx={gettingStyle(moment(data?.cell?.value, 'YYYY-MM-DD HH:mm').isValid() ? theme => theme.palette.text.primary : theme => theme.palette.error.main)}>
                    { moment(data?.cell?.value, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') }
                </Box>
            )
        // END TIME
        case 4:
            return (
                <Box sx={gettingStyle(moment(data?.cell?.value, 'YYYY-MM-DD HH:mm').isValid() ? theme => theme.palette.text.primary : theme => theme.palette.error.main)}>
                    { moment(data?.cell?.value, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') }
                </Box>
            )
        // PERSON NUMBER
        default:
            return (
                <Box sx={gettingStyle(undefined)}>
                    { JSON.stringify(data?.cell?.value) }
                </Box>
            )

    }
})

const Excel = ({
    xls, setXls,
    verified, setVerified,
    error, setError
}) => {
    const { t } = useTranslation()

    const theme = useTheme()

    const [openAlert, setOpenAlert] = useState(false)
    const [loadingChecking, setLoadingChecking] = useState(false)

    const columnLabels = [
        t('schedule.table.modals.load_shifts.xls.columns.person_number'),
        t('schedule.table.modals.load_shifts.xls.columns.date'),
        t('schedule.table.modals.load_shifts.xls.columns.type'),
        t('schedule.table.modals.load_shifts.xls.columns.start_time'),
        t('schedule.table.modals.load_shifts.xls.columns.end_time'),
    ];

    const uploadingFile = (file) => {
        setVerified(false)

        const reader = new FileReader()

        reader.onload = (e) => {
            const data = e.target.result

            const workbook = XLSX.read(data, { type: 'binary', cellDates: true, raw: true })
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]
            const rows = XLSX.utils.sheet_to_json(worksheet, {header: 1})

            rows.shift()
            
            const xls = []

            for(const row of rows){
                const xls_row = []

                for(const cell of row){
                    xls_row.push({
                        value: cell instanceof Date ? moment(cell).format('YYYY-MM-DD HH:mm') : cell,
                        DataViewer: (data) => <Cell data={data} />
                    })
                }

                xls.push(xls_row)
            }
            console.log('xls:', xls)
            setXls(xls)
        }

        reader.readAsArrayBuffer(file)
    }

    const openSystemManagerFile = () => {
        document.getElementById("fileInput").click();
    };

    return (
        <>
            <input 
                type={'file'} 
                id={'fileInput'}
                accept=".xls,.xlsx"
                style={{ display: 'none' }}
                onChange={(event) => {
                    const file = event.target.files[0]

                    if(file){
                        uploadingFile(file)
                    }
                }}
            />

            <Collapse 
                sx={{p: 0, mb: 1.5}}
                in={openAlert}
            >
                <Alert
                    severity={error ? "error" : "success"}
                    onClose={() => {
                        setOpenAlert(false)
                    }}
                >
                    { error ? t('schedule.table.modals.load_shifts.invalid_rows') : t('schedule.table.modals.load_shifts.valid_rows') }
                </Alert>
            </Collapse>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                }}
            >
                <Box
                    sx={{
                        pl: 7,
                        width: '100%',
                    }}
                >
                    <ToggleButtonGroup exclusive>
                        <ToggleButton
                            type='file'
                            onClick={() => openSystemManagerFile()}
                            value={'upload'}
                        >
                            <FileUploadIcon />
                        </ToggleButton>

                        <ToggleButton
                            type='file'
                            onClick={() => { window.open('/examples/example.xlsx', '_blank') }}
                            value={'download'}
                        >
                            <FileDownloadIcon />
                        </ToggleButton>

                        <ToggleButton 
                            disabled={loadingChecking || xls.filter(r => r[0]?.value !== '' && r[1]?.value !== '' && r[2]?.value !== '' && r[3]?.value !== '' && r[4]?.value !== '').length === 0}
                            onClick={() => checkingRows(xls, setError, setVerified, setOpenAlert, setLoadingChecking)}
                            value={'check'}
                        >
                            { loadingChecking 
                                ? <CircularProgress size={23} />
                                : <PublishedWithChangesIcon />
                            }
                        </ToggleButton>
                        
                        <Tooltip 
                            title={
                                <>
                                    {t('schedule.table.modals.load_shifts.xls.tooltips.info_person_number', {key: t('schedule.table.modals.load_shifts.xls.columns.person_number')})}
                                    <br />
                                    {t('schedule.table.modals.load_shifts.xls.tooltips.info_date', {key: t('schedule.table.modals.load_shifts.xls.columns.date')})}
                                    <br />
                                    {t('schedule.table.modals.load_shifts.xls.tooltips.info_type', {key: t('schedule.table.modals.load_shifts.xls.columns.type')})}
                                    <br />
                                    {t('schedule.table.modals.load_shifts.xls.tooltips.info_start_time', {key: t('schedule.table.modals.load_shifts.xls.columns.start_time')})}
                                    <br />
                                    {t('schedule.table.modals.load_shifts.xls.tooltips.info_end_time', {key: t('schedule.table.modals.load_shifts.xls.columns.end_time')})}
                                </>
                            }
                        >
                            <span>
                                <ToggleButton 
                                    disabled
                                    value={'info'}
                                >
                                    <InfoIcon />
                                </ToggleButton>
                            </span>
                        </Tooltip>
                    </ToggleButtonGroup>
                </Box>

                <Box
                    sx={{
                        pt: 1,
                        width: 48,
                        //background: 'green'
                    }}
                >
                    <ToggleButtonGroup
                        orientation="vertical"
                        exclusive
                    >
                        <ToggleButton
                            onClick={() => { 
                                setXls(xls.slice(0, -1)) 
                                setVerified(false)
                            }}
                            value={'delete'}
                        >
                            <VerticalAlignTopIcon />
                        </ToggleButton>

                        <ToggleButton
                            onClick={() => { 
                                setXls([...xls, [
                                    {
                                        value: '',
                                        DataViewer: (data) => <Cell data={data} />
                                    },
                                    {
                                        value: '',
                                        DataViewer: (data) => <Cell data={data} />
                                    },
                                    {
                                        value: '',
                                        DataViewer: (data) => <Cell data={data} />
                                    },
                                    {
                                        value: '',
                                        DataViewer: (data) => <Cell data={data} />
                                    },
                                    {
                                        value: '',
                                        DataViewer: (data) => <Cell data={data} />
                                    },
                                ]])
                                setVerified(false)
                            }}
                            value={'add'}
                        >
                            <VerticalAlignBottomIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                <Box
                    sx={{
                        flexGrow: 1,
                        overflow: 'auto',
                        mt: -11.8,
                        ml: 7,
                        borderRadius: 0.75,
                        border: (theme) => getBorder(theme.palette.mode),
                        maxHeight: 400,
                        minHeight: 140
                    }}
                >
                    <Spreadsheet
                        darkMode={theme.palette.mode === 'dark' ? true : false}
                        data={xls}
                        columnLabels={columnLabels}
                        onChange={(rows) => { 
                            setVerified(false)
                            setXls(rows)
                        }} 
                    />
                </Box>
            </Box>
        </>
    )
}


const LoadShifts = ({open, setOpen, reloading}) => {
    const { t } = useTranslation()

    const {
        handlerAlert,
    } = useContext(GlobalContext)

    const [xls, setXls] = useState([])
    const [verified, setVerified] = useState(false)
    const [error, setError] = useState(false)

    const [loadingAddShifts, setLoadingAddShifts] = useState(false)

    const closing = () => {
        setOpen(false)
        setVerified(false)
        setError(false)
        setLoadingAddShifts(false)
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => closing()}
                maxWidth='md'
            >
                <DialogTitle
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Typography variant='body3'>{ t('schedule.table.modals.load_shifts.title') }</Typography>
                </DialogTitle>

                <DialogContent
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                    dividers
                >
                    <Excel 
                        xls={xls}
                        setXls={setXls}
                        verified={verified} 
                        setVerified={setVerified}
                        error={error}
                        setError={setError}
                    />
                </DialogContent>

                <DialogActions
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Tooltip 
                        title={ 
                            verified===false || 
                            error===true ||
                            xls.length === 0
                            ?
                                <>
                                    <List dense={true}>
                                        { verified===false &&
                                            <ListItem>
                                                <ListItemText primary={t('schedule.table.modals.buttons.load.validation_missing')}/>
                                            </ListItem>
                                        }

                                        { error===true && xls.length > 0 &&
                                            <ListItem>
                                                <ListItemText primary={t('schedule.table.modals.buttons.load.data_with_errors')}/>
                                            </ListItem>
                                        }

                                        { xls.length === 0 &&
                                            <ListItem>
                                                <ListItemText primary={t('schedule.table.modals.buttons.load.no_data')}/>
                                            </ListItem>
                                        }
                                    </List>
                                </>
                                :
                                ''
                        }
                    >
                        <span>
                            <LoadingButton
                                variant='contained'
                                disableElevation 
                                onClick={() => {
                                    const shifts = xls.map(row => {
                                        const start_date = moment(row[3]?.value, 'YYYY-MM-DD HH:mm').set({hour: 0, minute: 0, second: 0, millisecond: 0})
                                        const end_date = moment(row[4]?.value, 'YYYY-MM-DD HH:mm').set({hour: 0, minute: 0, second: 0, millisecond: 0})

                                        return {
                                            person_number: row[0]?.value,
                                            date: moment(row[1]?.value, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'),
                                            override: false,
                                            override_paycode: false,
                                            segments: [
                                                {
                                                    type: row[2]?.value,
                                                    start_time: moment(row[3]?.value, 'YYYY-MM-DD HH:mm').format('HH:mm'),
                                                    end_time: moment(row[4]?.value, 'YYYY-MM-DD HH:mm').format('HH:mm'),
                                                    offset_start_day: 1,
                                                    offset_end_day: Math.abs(end_date.diff(start_date, 'days')) + 1,
                                                }
                                            ]
                                        }
                                    })

                                    bulkLoading(
                                        setLoadingAddShifts,
                                        shifts,
                                        reloading,
                                        closing,
                                        handlerAlert
                                    )
                                }}
                                color='success'
                                loading={loadingAddShifts}
                                disabled={
                                    verified===false || 
                                    error===true ||
                                    xls.length === 0
                                }
                            >
                                { t('schedule.table.modals.buttons.load.title') }
                            </LoadingButton>
                        </span>
                    </Tooltip>

                    <Button 
                        variant='contained' 
                        disableElevation 
                        color='error' 
                        onClick={() => { closing() }}
                        sx={{mr: 1.5}}
                    >
                        {t('schedule.table.modals.buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export {
    LoadShifts
}