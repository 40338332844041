import { DataGridPro } from '@mui/x-data-grid-pro';
import { LinearProgress } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FilterTotal } from '../../filter/FilterTotal';
import { GlobalContext } from '../../../GlobalContext';
import { gettingTotals } from '../../../utils/Total';
import { Context } from '../timecard/Context';
import { useTranslation } from 'react-i18next';


const Table = ({open, range1, employee_id}) => {
    const { t } = useTranslation()
    
    const { 
        handelerAlert,
    } = useContext(GlobalContext)

    const {
        range3,
    } = useContext(Context)

    const columns = useMemo(() => [
        {
            field: 'paycode', 
            headerName: t('totals.table.columns.paycode'),
            flex: 1.5,
        },
        /* {
            field: 'cost_center', 
            headerName: 'Centro de costo',
            flex: 3,
        }, */
        {
            field: 'amount', 
            headerName: t('totals.table.columns.amount'),
            align: 'center',
            headerAlign: 'center',
            minWidth: 150,
            width: 150,
        },
    ], [t])

    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)

    const reloading = useCallback(() => {
        gettingTotals(setLoading, range3, employee_id, setRows, handelerAlert)
    }, [employee_id, handelerAlert, range3])

    useEffect(() => {
        if(open){
            reloading()
        }
    }, [open, reloading])

    return (
        <>
            <DataGridPro
                showCellVerticalBorder
                showColumnVerticalBorder
                loading={loading}
                columns={columns}
                rows={rows}
                slots={{
                    toolbar: useCallback(() => <FilterTotal loading={loading} setLoading={setLoading} setRows={setRows} employee_id={employee_id}/>, [employee_id, loading]),
                    loadingOverlay: LinearProgress,
                }}
                sx={{
                    height: '38vh',
                    border: theme => `1px solid ${theme.palette.divider}`,
                }}
                disableRowSelectionOnClick
                hideFooter

            />
        </>
    )
}

export { 
    Table 
}