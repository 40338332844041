import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, List, ListItem, ListItemText, TextField, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { GlobalContext } from '../../../GlobalContext';
import { Context } from '../../table/overtime/Context';
import { applyingOvertime } from '../../../utils/Overtime';
import moment from 'moment';

const PartialText = ({row}) => {
    const { t } = useTranslation()

    const { 
        setRows,
    } = useContext(Context)

    const [partial, setPartial] = useState(row.partial_amount)

    return (
        <>
            <TextField
                label={t('overtime.table.rows.partial.title')}
                placeholder={t('overtime.table.rows.partial.placeholder')}
                value={partial}
                error={!/^\d{1,2}:[0-5]\d$/.test(partial)}
                InputProps={{
                    endAdornment: 
                        <InputAdornment sx={{width: '100%', mr: -0.75}} position="end">
                            { `/ ${row.amount}` }
                            <WatchLaterIcon />
                        </InputAdornment>,
                }}
                onChange={(event) => {
                    setPartial(event.target.value)
                }}
                onBlur={(event) => {
                    setRows(prev => prev.map(r => r.id === row.id ? { ...r, partial_amount: event.target.value } : r))
                }}
                variant='outlined'
                size='small'
                sx={{
                    width: '160px',
                }}
            />

        </>
    )
}

const Partial = ({open, setOpen, data, checked}) => {
    const { t } = useTranslation()

    const {
        //apiRef,
        //rows,
        setRows,
        reloading,
    } = useContext(Context)

    const {
        handlerAlert,
    } = useContext(GlobalContext)

    const [amount, setAmount] = useState('00:00')
    const [overtimes, setOvertimes] = useState([])
    const [loading, setLoading] = useState(false)

    const closing = () => {
        setOpen(false)
        setAmount('00:00')
        setLoading(false)
    }

    useEffect(() => {
        if(open){
            setOvertimes(data.filter(d => checked.includes(d.id)).filter(d => d.status === 'pending'))
        }
    }, [checked, data, open])

    return (
        <>
            <Dialog
                fullWidth
                open={open} 
                onClose={() => { closing() }}
            >
                <DialogTitle>
                    <Typography variant='body3'>
                        {t('overtime.table.modals.partial.title')}
                    </Typography>
                </DialogTitle>
                
                { data.filter(d => checked.includes(d.id)).some(d => d.status !== 'pending') && 
                    <DialogContent
                        sx={{
                            backgroundColor: (theme) => theme.palette.background.default,
                            borderBottom: 'none'
                        }}
                        dividers
                    >
                        <Grid container spacing={2}>
                            <Grid 
                                item 
                                xs={12}
                                sx={{
                                    height: '100%',
                                }}
                            >
                                <Alert
                                    severity="warning"
                                    sx={{
                                        width: '100%',
                                        opacity: 1,
                                    }}
                                    variant="filled"
                                >
                                    <AlertTitle>
                                        {t('overtime.table.modals.partial.alerts.already_managed.title')}
                                    </AlertTitle>

                                    {t('overtime.table.modals.partial.alerts.already_managed.message')}
                                </Alert>
                            </Grid>
                        </Grid>
                    </DialogContent>
                }

                <DialogContent
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                        borderBottom: 'none',
                        borderRadius: '0px',
                        height: '100%',
                    }}
                    dividers
                >
                    <Grid sx={{mt: 0.25}} container spacing={2}>
                        <Grid item xs={9}>
                            <Typography variant='body3'>
                                {t('overtime.table.modals.partial.message1')}
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                sx={{mt: -0.5, mb: 1.5}}
                                label={t('overtime.table.rows.partial.title')}
                                placeholder={t('overtime.table.rows.partial.placeholder')}                                
                                fullWidth
                                size='small'
                                value={amount}
                                error={!/^\d{1,2}:[0-5]\d$/.test(amount)}
                                InputProps={{
                                    endAdornment: 
                                        <InputAdornment sx={{mr:-0.5}} position="end">
                                            <WatchLaterIcon />
                                        </InputAdornment>,
                                }}
                                onChange={(event) => {
                                    //.replace(/^0+/, "")
                                    let nrows = []
                                    setAmount(event.target.value)
                                    setRows(prev => { 
                                        nrows = prev.map(row => {
                                            if(checked.includes(row.id) && row.status === 'pending'){
                                                return { 
                                                    ...row, 
                                                    partial_amount: moment.duration(event.target.value).asSeconds() >= moment.duration(row.amount).asSeconds() ? row.amount : event.target.value
                                                }
                                            }else{
                                                return row
                                            }
                                        })

                                        return nrows
                                    })

                                    setOvertimes(nrows.filter(d => checked.includes(d.id)).filter(d => d.status === 'pending'))
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogContent dividers>                    
                    <Typography variant='body3'>
                        {t('overtime.table.modals.partial.message2')}
                        
                        { overtimes.length !== 0 &&
                            <List>
                                { overtimes.map((row, index) => 
                                    <ListItem 
                                        sx={{mt: 3}}
                                        key={new Date().getTime() + index}
                                        secondaryAction={
                                            <Box sx={{mr: -2}}>
                                                <PartialText 
                                                    row={row} 
                                                    checked={checked} 
                                                    setOvertimes={setOvertimes}
                                                />
                                            </Box>
                                        }
                                    >
                                        <ListItemText 
                                            primary={
                                                <Box 
                                                    sx={{
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        width: '370px',
                                                    }}
                                                >
                                                    {`${row.date} - ${row.full_name}`}
                                                </Box>}
                                            />
                                    </ListItem>
                                )}
                            </List>
                        }
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Tooltip 
                        title={
                            data.filter(d => checked.includes(d.id)).filter(d => d.status === 'pending').length === 0 || overtimes.some(d => !/^\d{1,2}:[0-5]\d$/.test(d.partial_amount)) ?
                                <List dense={true}>
                                    { data.filter(d => checked.includes(d.id)).filter(d => d.status === 'pending').length === 0 &&
                                        <ListItem>
                                            <ListItemText primary={t('overtime.table.modals.partial.buttons.approve.overtime_already_managed')}/>
                                        </ListItem>
                                    }
                                    
                                    { overtimes.some(d => !/^\d{1,2}:[0-5]\d$/.test(d.partial_amount)) &&
                                        <ListItem>
                                            <ListItemText primary={t('overtime.table.modals.partial.buttons.approve.format_is_invalid')}/>
                                        </ListItem>
                                    }
                                </List>
                            :
                            ''
                        }
                    >
                        <span>
                            <LoadingButton
                                variant='contained'
                                disableElevation 
                                onClick={() => {
                                    applyingOvertime(
                                        setLoading,
                                        overtimes,
                                        closing,
                                        reloading,
                                        handlerAlert,
                                    )
                                }}
                                color='primary'
                                loading={loading}
                                disabled={data.filter(d => checked.includes(d.id)).filter(d => d.status === 'pending').length === 0 || overtimes.some(d => !/^\d{1,2}:[0-5]\d$/.test(d.partial_amount))}
                            >
                                {t('overtime.table.modals.partial.buttons.approve.title')}
                            </LoadingButton>
                        </span>
                    </Tooltip>

                    <Button
                        sx={{ mt: 0.5, mr: 2 }}
                        disableElevation
                        color='error'
                        variant='contained'
                        onClick={() => { closing() }}
                    >
                        {t('overtime.table.modals.partial.buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export { 
    Partial
}