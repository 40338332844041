import { useMsal } from '@azure/msal-react'
import { LoadingButton } from '@mui/lab'
import { Box, Skeleton, TextField, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { useContext, useEffect, useState } from 'react'
import { gettingCompanyLogo, registering } from '../utils/Login'
import { GlobalContext } from '../GlobalContext'
import { useTranslation } from 'react-i18next'

const SignUp = () => {
    const theme = useTheme()
    const { t } = useTranslation()
    const { instance, accounts } = useMsal()
    const { setAuthenticated, handlerAlert } = useContext(GlobalContext)

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [loadingLogo, setLoadingLogo] = useState(true)
    const [loadingRegister, setLoadingRegister] = useState(false)

    const handleLogoutRedirect = () => {
		const logoutRequest = {
			account: instance.getActiveAccount()[0],
			postLogoutRedirectUri: process.env.REACT_APP_MS_REDIRECTURI + 'signin/',
		};

        sessionStorage.clear()

		instance
		.logoutRedirect(logoutRequest)
		.then(response => console.log('response: ', response))
		.catch(error =>  console.error(error))
	}

    useEffect(() => {
        gettingCompanyLogo(
			setLoadingLogo, 
			theme.palette.mode
		)

    }, [theme.palette.mode])

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: '100vh'
                }}
            >
                <Box
                    sx={{
                        width: 400, 
                        padding: 7, 
                        borderRadius: 5, 
                        border: (theme) => theme.palette.mode === 'dark' ? '1px solid rgba(255, 255, 255, 0.12)' : '1px solid rgba(0, 0, 0, 0.12)',
                        background: (theme) => theme.palette.mode === 'dark' ? grey[900] : grey[50],
                    }}
                >
                    <form onSubmit={(event) => {}}>
                        <center>
                            { loadingLogo ? 
                                <Skeleton 
                                    sx={{
                                        marginTop: '20px', 
                                        marginBottom: '20px',
                                        borderRadius: 1
                                    }} 
                                    variant="rectangular" 
                                    height={100}
                                    width={100}
                                />
                                :
                                <img
                                    src = {JSON.parse(sessionStorage.getItem('images'))?.logo_login}
                                    alt='logo-app'
                                    height={100}
                                    width={100}
                                    style={{
                                        marginTop: 20, 
                                        marginBottom: 20,
                                    }}
                                />
                            }
                        </center>

                        <TextField 
                            sx={{mb: 3}}
                            id="username"
                            label="Username"
                            type="text"
                            variant="outlined"
                            fullWidth
                            value={username}
                            onChange={(event) => setUsername(event.target.value)}
                        />

                        <TextField 
                            sx={{mb: 3}}
                            id="password"
                            label="password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />

                        <TextField 
                            id="Email"
                            label="Email"
                            type="email"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={accounts[0]?.username}
                        />

                        <LoadingButton
                            sx={{mt: 3, mb: 3, height: 50}}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            loading={loadingRegister}
                            disableElevation
                            onClick={() => {
                                registering(
                                    setLoadingRegister,
                                    setAuthenticated,
                                    username,
                                    accounts[0]?.username,
                                    handlerAlert,
                                    t
                                )
                            }}
                        >
                            { 'Sign Up' }
                        </LoadingButton>
                    </form>

                    <span 
                        style={{cursor: 'pointer'}}
                        onClick={handleLogoutRedirect}
                    >
                        back to sign in
                    </span>
                </Box>
            </Box>
        </>
    )
}

export { 
    SignUp
}