//import axios from "axios"
import axios from "axios"
import Isotipo from '../assets/gr-isotipo-oscuro-morado.png'
import DarkImagotipo from '../assets/gr-imagotipo-oscuro-morado-dark.png'
import LightImagotipo from '../assets/gr-imagotipo-oscuro-morado-light.png'

const validateEmail = (email) => {
	const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9]+\.[a-zA-Z]+$/
	return regex.test(email)
}
/*
El nombre de usuario debe al menos tener 4 caracteres.
El nombre de usuario debe aceptar caracteres especiales.
EL nombre de usuario debe ser alfabetico.
*/
const validateUsername = (username) => {
    const regex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{4,}$/ // eslint-disable-line

    return regex.test(username)
}

/*
La contraseña debe contener al menos una letra minúscula.
La contraseña debe contener al menos una letra mayúscula.
La contraseña debe contener al menos un número.
La contraseña debe tener al menos 8 caracteres. 
*/
const validatePassword = (password) => {
	//const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
  	return true //regex.test(password)
}

const savingCredential = (response, signin, i18n) => {
    const today = new Date();

    sessionStorage.setItem('token', response.data.token)

    sessionStorage.setItem('account', JSON.stringify({
        id: response.data.user.id,
        username: response.data.user.username,
        name: response.data.user.name,
        lastname: response.data.user.lastname,
        email: response.data.user.email,
        expire: new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() + 7
        ),
        signin
    }))

    sessionStorage.setItem('company', JSON.stringify({
        id: response.data.company.id,
        name: response.data.company.name,
        integration: response.data.company.integration,
        expire: new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() + 7
        )
    }))

    sessionStorage.setItem('privileges', JSON.stringify(
        response.data.permissions.template
    ))

    sessionStorage.setItem('language', response.data.language)

    sessionStorage.setItem('config', JSON.stringify({
        theme: 'ligth',
        language: response.data.language,
        date_format: 'DD/MM/YYYY'
    }))

    i18n.changeLanguage(response.data.language)
}

const siginingIn = async (
    event, 
    setLoading, 
    username, 
    password, 
    setAuthenticated, 
    setUsername, 
    setPassword, 
    text_fail, 
    i18n,
    redirect,
) => {
	event.preventDefault()
    
	setLoading(true)

    let data = new FormData();
    data.append('username', username.value);
    data.append('password', password.value);

    const headers = {
        'Content-Type': 'application/json'
    }

    return axios.post(process.env.REACT_APP_API_DOMAIN + 'auth/access_token', data, { headers: headers })
    .then((response) => {
        savingCredential(response, 'SIM', i18n)
        setAuthenticated(true)
    })
    .catch((error) => {
        console.error(error.message)

        setUsername({value: '', error: true, helperText: text_fail})
        setPassword({value: '', error: true, helperText: text_fail})
    })
    .finally(() => { 
        setLoading(false)
    })
}

const signingOut = (instance) => {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('account')
    sessionStorage.removeItem('company')
    sessionStorage.removeItem('privileges')
    sessionStorage.removeItem('language')
    sessionStorage.removeItem('config')
    sessionStorage.removeItem('employees')
    sessionStorage.removeItem('schedule')
    sessionStorage.removeItem('openLeft')

    const logoutRequest = {
        account: instance.getActiveAccount() ? instance.getActiveAccount()[0] : undefined,
        postLogoutRedirectUri: process.env.REACT_APP_MS_REDIRECTURI + 'signin/',
    };

    instance
    .logoutRedirect(logoutRequest)
    .then(response => console.log('response: ', response))
    .catch(error =>  console.error(error))
    .finally(() => {
        sessionStorage.clear()
        localStorage.clear()
    })
}

const gettingCompanyLogo = async (
    setLoading,
    theme,
) => {
    setLoading(true)
    
    const subdomain = window.location.hostname.split('.')[0]

    return axios.get(process.env.REACT_APP_API_DOMAIN + `company?sub_domain=${subdomain}`)
    .then((response) => {
        if(response.data && response.data.logo_login){
            sessionStorage.setItem('images', JSON.stringify({
                logo: response.data.logo,
                logo_login: response.data.logo_login
            }))
        }
    })
    .catch((error) => { 
        sessionStorage.setItem('images', JSON.stringify({
            logo: theme = 'dark' ? DarkImagotipo : LightImagotipo,
            logo_login: Isotipo
        }))
    })
    .finally(() => { setLoading(false) })   
}

const registering = async (
    setLoading,
    setAuthenticated,
    username,
    email,
    handlerAlert,
    t,
) => {
    setLoading(true)

    return axios.post(process.env.REACT_APP_API_DOMAIN + 'auth/sso/register_user', {
        username,
        email
    })
    .then((response) => {
        const today = new Date();

        sessionStorage.setItem('token', response.data.token)

        sessionStorage.setItem('account', JSON.stringify({
            id: response.data.user.id,
            username: response.data.user.username,
            name: response.data.user.name,
            lastname: response.data.user.lastname,
            email: response.data.user.email,
            expire: new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() + 7
            ),
            signin: 'SIM'
        }))

        sessionStorage.setItem('company', JSON.stringify({
            id: response.data.company.id,
            name: response.data.company.name,
            integration: response.data.company.integration,
            expire: new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() + 7
            )
        }))

        sessionStorage.setItem('privileges', JSON.stringify(
            response.data.permissions.template
        ))

        sessionStorage.setItem('language', response.data.language)

        sessionStorage.setItem('config', JSON.stringify({
            theme: 'ligth',
            language: response.data.language,
            date_format: 'DD/MM/YYYY'
        }))

        setAuthenticated(true);

        window.location.href = '/';
    })
    .catch((error) => { 
        console.error(error.message) 
        switch (error.response.status) {
            case 401:
                //window.location.href = '/signup'
                handlerAlert(
                    t('sign_in.alerts.401.title'),
                    t('sign_in.alerts.401.message'),
                    'error'
                )
                break
            case 400:
                handlerAlert(
                    t('sign_in.alerts.400.title'),
                    t('sign_in.alerts.400.message'),
                    'error'
                )

                break
            default:
                break
        }
    })
    .finally(() => { 
        setLoading(false)
    })
}

export {
    validateEmail,
    validateUsername,
    validatePassword,
    savingCredential,
    siginingIn,
    signingOut,
    registering,
    gettingCompanyLogo
}