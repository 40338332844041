import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { FilterTimecard } from '../../filter/FilterTimecard'
import { Box, Collapse, LinearProgress } from '@mui/material'
import { Context } from './Context'
import { useTranslation } from 'react-i18next'
import { ShiftCell } from './ShiftCell'
import { EditPunchCell, PunchCell } from './PunchCell'
import { ExceptionCell } from './ExceptionCell'
import { PaycodeCell, PaycodeEditCell } from './PaycodeCell'
import { WorkedHoursCell } from './WorkedHoursCell'
import { ContextMenu } from '../../context_menu/ContextMenu'
import RefreshIcon from '@mui/icons-material/Refresh';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import GroupsIcon from '@mui/icons-material/Groups';
import CalculateIcon from '@mui/icons-material/Calculate';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import { getBorder } from '../../../utils/Styles'
import { Table as Audit } from '../audit/Table'
import { Table as Total } from '../total/Table'
import { OvertimeCell } from './OvertimeCell'
import { ConfirmAddPunch } from '../../dialog/timecard/ConfirmAddPunch'
import moment from 'moment'
import { AddPaycode } from '../../dialog/timecard/AddPaycode'
import WalletIcon from '@mui/icons-material/Wallet';
import { ApproveOvertime } from '../../dialog/timecard/ApproveOvertime'
import { RecantOvertime } from '../../dialog/timecard/RecantOvertime'
import { useNavigate } from 'react-router-dom'

const Table = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const {
        apiRef,
        loadingGettingTimecard,
        loadingGettingSchedule,
        loadingGettingOpenPeriod,
        rows,
        checked,
        //setChecked,
        reloading,
        row, 
        setRow,
        openConfirmAddPunch, 
        setOpenConfirmAddPunch,
        openApprove, 
        setOpenApprove,
        openRecant, 
        setOpenRecant,
        addOpenPaycode, 
        setAddOpenPaycode,
        action, 
        setAction,
        periods,
        employee,
        range1,
        smartgroup,
    } = useContext(Context)

    const [openAudits, setOpenAudits] = useState(false)
    const [openTotals, setOpenTotals] = useState(false)
    const [cell, setCell] = useState({ column: null, row: null })
    const [contextMenu, setContextMenu] = useState(null)

    const columns = useMemo(() => [
        { 
            field: 'date', 
            headerName: t('timecard.table.columns.date'),
            width: 150,
            minWidth: 150,
            align: 'center',
            valueFormatter: (params) => {
                const str = moment(params.value, 'YYYY-MM-DD').format('ddd DD MMM')
                
                return str.charAt(0).toUpperCase() + str.slice(1)
            },
            //filterOperators: getGridStringOperators().filter(operator => operator.value !== 'isAnyOf'),
        },
        { 
            field: 'overtime', 
            headerName: t('timecard.table.columns.overtimes'),
            minWidth: 120,
            width: 120,
            editable: false,
            renderCell: (params) => <OvertimeCell params={params} setOpenApprove={setOpenApprove} setOpenRecant={setOpenRecant}/>
        },
        { 
            field: 'shifts', 
            headerName: t('timecard.table.columns.shifts'),
            minWidth: 185,
            width: 185,
            editable: false,
            renderCell: (params) => <ShiftCell params={params} />
        },
        { 
            field: 'punch_in', 
            headerName: t('timecard.table.columns.in_punches'),
            flex: 1,
            minWidth: 225,
            width: 225,
            editable: true,
            renderCell: (params) => <PunchCell params={params} setAddOpenPaycode={setAddOpenPaycode} setAction={setAction} setCell={setCell}/>,
            renderEditCell: (params) => <EditPunchCell params={params} />,
        },
        { 
            field: 'punch_out', 
            headerName: t('timecard.table.columns.out_punches'),
            flex: 1,
            minWidth: 225,
            width: 225,
            editable: true,
            renderCell: (params) => <PunchCell params={params} setAddOpenPaycode={setAddOpenPaycode} setAction={setAction} setCell={setCell}/>,
            renderEditCell: (params) => <EditPunchCell params={params} />,
        },
        { 
            field: 'exceptions', 
            headerName: t('timecard.table.columns.exceptions'),
            flex: 1,
            minWidth: 225,
            width: 225,
            editable: false,
            renderCell: (params) => <ExceptionCell params={params} setAddOpenPaycode={setAddOpenPaycode} setAction={setAction} setCell={setCell}/>,
        },
        { 
            field: 'paycodes', 
            headerName: t('timecard.table.columns.paycodes'),
            minWidth: 205,
            width: 205,
            editable: true,
            renderCell: (params) => <PaycodeCell params={params} />,
            renderEditCell: (params) => <PaycodeEditCell params={params} />,
        },
        { 
            field: 'worked_hours', 
            headerName: t('timecard.table.columns.worked_hours'),
            minWidth: 150,
            width: 150,
            align: 'center',
            editable: false,
            renderCell: (params) => <WorkedHoursCell params={params} />,
        },
    ], [setAction, setAddOpenPaycode, setOpenApprove, setOpenRecant, t])

    const options = useMemo(() => [
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).schedule.get_paycodes && JSON.parse(sessionStorage.getItem('privileges')).schedule.add_paycode_hours,
            name: t('timecard.table.menu.add_paycode'),
            click: () => {
                setAddOpenPaycode(true)
                setAction('timecard')
                setContextMenu(null)
            },
            icon: <WalletIcon />,
            group: 1,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).employees.show_page,
            name: t('timecard.table.menu.go_to_employees'),
            click: () => { 
                sessionStorage.setItem('employees', JSON.stringify({
                    environment: JSON.parse(sessionStorage.getItem('employees'))?.environment ? JSON.parse(sessionStorage.getItem('employees')).environment : null,
                    redirect: {
                        start: range1[0].format('YYYY-MM-DD'),
                        end: range1[1].format('YYYY-MM-DD'),
                        smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)).map(sg => sg),
                        employees: [employee[0]],
                    },
                }))

                navigate('/employees')
            },
            icon: <GroupsIcon />,
            group: 2,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).schedule.show_page,
            name: t('timecard.table.menu.go_to_schedule'),
            click: () => { 
                sessionStorage.setItem('schedule', JSON.stringify({
                    environment: JSON.parse(sessionStorage.getItem('schedule'))?.environment ? JSON.parse(sessionStorage.getItem('schedule')).environment : null,
                    redirect: {
                        start: range1[0].format('YYYY-MM-DD'),
                        end: range1[1].format('YYYY-MM-DD'),
                        smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)).map(sg => sg),
                        employees: [employee[0]],
                    },
                }))

                navigate('/schedule')
            },
            icon: <CalendarMonthIcon />,
            group: 2,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).overtime.show_page,
            name: t('timecard.table.menu.go_to_overtime'),
            click: () => { 
                sessionStorage.setItem('overtime', JSON.stringify({
                    environment: JSON.parse(sessionStorage.getItem('overtime'))?.environment ? JSON.parse(sessionStorage.getItem('overtime')).environment : null,
                    redirect: {
                        start: range1[0].format('YYYY-MM-DD'),
                        end: range1[1].format('YYYY-MM-DD'),
                        smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)).map(sg => sg),
                        employees: [employee[0]],
                    },
                }))

                navigate('/overtime')
            },
            icon: <MoreTimeIcon />,
            group: 2,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).requests.show_page,
            name: t('timecard.table.menu.go_to_requests'),
            click: () => { 
                sessionStorage.setItem('requests', JSON.stringify({
                    environment: JSON.parse(sessionStorage.getItem('requests'))?.environment ? JSON.parse(sessionStorage.getItem('requests')).environment : null,
                    redirect: {
                        start: range1[0].format('YYYY-MM-DD'),
                        end: range1[1].format('YYYY-MM-DD'),
                        smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)).map(sg => sg),
                        employees: [employee[0]],
                    },
                }))

                navigate('/requests')
            },
            icon: <AllInboxIcon />,
            group: 2,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: true,
            name: openTotals ? t('timecard.table.menu.close_totals') : t('timecard.table.menu.open_totals'),
            click: () => {
                setContextMenu(null)
                setOpenTotals(!openTotals)
                setOpenAudits(false)
            },
            icon: <CalculateIcon />,
            group: 3,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: true,
            name: t(openAudits ? 'timecard.table.menu.close_audit' : 'timecard.table.menu.open_audit'),
            click: () => {
                setContextMenu(null)
                setOpenAudits(!openAudits)
                setOpenTotals(false)
            },
            icon: <PendingActionsIcon />,
            group: 3,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: true,
            name: t('timecard.table.menu.reload'),
            click: () => {
                setContextMenu(null)
                reloading()
            },
            icon: <RefreshIcon />,
            group: 4,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
    ], [employee, navigate, openAudits, openTotals, range1, reloading, setAction, setAddOpenPaycode, smartgroup, t])
    
    return (
        <>
            <Box
                sx={{
                    [`.${gridClasses.cell}.open`]: { backgroundColor: (theme) => theme.palette.background.default },
                    [`.${gridClasses.cell}.close`]: { backgroundColor: (theme) => theme.palette.background.close },
                }}
            >
                <div
                    onContextMenu={(event) => {
                        event.preventDefault();

                        setContextMenu(
                            contextMenu === null ? 
                                { 
                                    mouseX: event.clientX + 2, 
                                    mouseY: event.clientY - 6 
                                } 
                                : 
                                null
                        )
                    }}
                >
                    <ConfirmAddPunch 
                        row={row}
                        open={openConfirmAddPunch}
                        setOpen={setOpenConfirmAddPunch}
                        reloading={reloading}
                    />

                    <ApproveOvertime
                        data={[{ id: employee[0]?.id, name: employee[0]?.full_name }]}
                        open={openApprove}
                        setOpen={setOpenApprove}
                    />

                    <RecantOvertime
                        data={[{ id: employee[0]?.id, name: employee[0]?.full_name }]}
                        open={openRecant}
                        setOpen={setOpenRecant}
                    />

                    <AddPaycode
                        data={[{ id: employee[0]?.id, name: employee[0]?.full_name }]}
                        open={addOpenPaycode} 
                        setOpen={setAddOpenPaycode} 
                        checked={[{ id: employee[0]?.id, name: employee[0]?.full_name }]}
                        cell={cell}
                        setCell={setCell}
                        action={action}
                        reloading={reloading}
                    />

                    <DataGridPro
                        apiRef={apiRef}
                        sx={{
                            border: theme => `1px solid ${theme.palette.divider}`,
                            height: `calc(100vh - ${openAudits || openTotals ? '55vh' : '120px'})`,
                        }}
                        slots={{
                            toolbar: useCallback(() => <FilterTimecard />, []),
                            loadingOverlay: LinearProgress,
                        }}
                        slotProps={{
                            row: {
                                onContextMenu: (e) => {
                                    setCell({
                                        column: e?.target?.dataset?.field,
                                        row: e?.currentTarget?.getAttribute('data-id').slice(-10),
                                        employee_id: employee[0]?.id,
                                    })
                                },
                            },
                        }}
                        onCellEditStop={(params) => {
                            console.log('stop', params)
        
                            if(params.field === 'punch_in' || params.field === 'punch_out'){
                                if(params.row.new_punch){
                                    setRow(params.row)
                                    setOpenConfirmAddPunch(true)
                                }
                            }
        
                            if(params.field === 'paycodes'){
                                console.log('stop', params)
                            }
                        }}
                        getCellClassName={(params) => {
                            if(!moment(params?.row?.date, 'YYYY-MM-DD').isValid()){
                                return ''
                            }

                            const date1 = moment(periods.find(period => period.person_number === params?.row?.person_number)?.end_date, 'YYYY-MM-DD')
                            const date2 = moment(params?.row?.date, 'YYYY-MM-DD')

                            const style = date1.isSameOrAfter(date2)

                            return  style ? 'close' : 'open'
                        }}
                        getRowHeight={params => { 
                            const { model } = params

                            let length = 64

                            for(const key of Object.keys(model).filter(k => Array.isArray(model[k]))){
                                if(key !== 'path'){
                                    let counter = 0

                                    if(model[key].length > 0){
                                        counter = counter + model[key].length * 47
                                    }

                                    if(counter > length){
                                        length = counter
                                    }
                                }
                            }

                            return length
                        }}
                        isCellEditable={(params) => params?.row?.open_period?.open}
                        columns={columns}
                        rows={useMemo(() => rows, [rows])}
                        showCellVerticalBorder
                        showColumnVerticalBorder
                        loading={loadingGettingTimecard || loadingGettingSchedule || loadingGettingOpenPeriod}
                        hideFooter
                        disableRowSelectionOnClick
                        disableColumnMenu
                    />

                    <ContextMenu
                        contextMenu={contextMenu}
                        setContextMenu={setContextMenu}
                        options={options}
                        checked={checked}
                    />
                </div>

                <Collapse 
                    sx={{
                        height: '55vh', 
                        mt: 2.5,
                        ml: -2.5, 
                        mr: -2.5,
                        p: 2.5,
                        borderTop: (theme) => getBorder(theme.palette.mode),
                    }} 
                    in={openAudits} 
                    timeout="auto" 
                    unmountOnExit
                >   
                    <Audit 
                        open={openAudits}
                        setOpen={setOpenAudits}
                        view={'timecard'}
                    />
                </Collapse>

                <Collapse 
                    sx={{
                        height: '55vh', 
                        mt: 2.5,
                        ml: -2.5, 
                        mr: -2.5,
                        p: 2.5,
                        borderTop: (theme) => getBorder(theme.palette.mode),
                    }} 
                    in={openTotals} 
                    timeout="auto" 
                    unmountOnExit
                >   
                    <Total open={openTotals} range1={range1} employee_id={employee.length > 0 ? employee[0]?.id : undefined}/>
                </Collapse>
            </Box>
        </>
    )
}

export { 
    Table
}