import { LinearProgress } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Context } from './Context';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextMenu } from '../../context_menu/ContextMenu';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { renderCell } from './RenderCell';
import { AddReport } from '../../dialog/reports/AddReport';

const Table = () => {
    const { t } = useTranslation()

    const {
        rows,
        loadingGettingResults,
        reloading,
    } = useContext(Context)

    const [contextMenu, setContextMenu] = useState(null)
    const [openAdd, setOpenAdd] = useState(false)

    const columns = useMemo(() => [
        {
            field: 'report',
            headerName: t('reports.table.columns.report'),
            flex: 2.5,
        },
        {
            field: 'created_at',
            headerName: t('reports.table.columns.created_at'),
            flex: 1,
        },
        {
            field: 'preview',
            headerName: t('reports.table.columns.preview'),
            flex: 0.5,
            renderCell,
        },
    ], [t]);

    const options = useMemo(() => [
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).reports.execute_report,
            name: t('reports.table.menu.create_new_report'),
            click: () => {
                setContextMenu(null)
                setOpenAdd(true)
            },
            icon: <AddIcon />,
            group: 1,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog:
                <AddReport 
                    data={rows.map(row => ({ id: row.id, name: row.full_name }))} 
                    open={openAdd} 
                    setOpen={setOpenAdd} 
                    checked={[]} 
                    reloading={reloading}
                />
        },
        {
            privilege: true,
            name: t('reports.table.menu.reload'),
            click: () => {
                setContextMenu(null)
                reloading()
            },
            icon: <RefreshIcon />,
            group: 2,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
    ], [openAdd, reloading, rows, t]);

    useEffect(() => {
        reloading()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div
                onContextMenu={(event) => {
                    event.preventDefault();
                    setContextMenu(
                        contextMenu === null ? 
                            { 
                                mouseX: event.clientX + 2, 
                                mouseY: event.clientY - 6 
                            } 
                            : 
                            null
                    )
                }}
            >
                <DataGridPro 
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    unstable_headerFilters
                    loading={loadingGettingResults}
                    columns={columns}
                    rows={rows}
                    slots={{
                        loadingOverlay: LinearProgress,
                    }}
                    sx={{
                        height: `calc(100vh - 120px)`,
                    }}
                    hideFooter
                />

                <ContextMenu
                    contextMenu={contextMenu}
                    setContextMenu={setContextMenu}
                    options={options}
                    checked={[]}
                />
            </div>
        </>
    )
}

export { 
    Table 
}