import React, { useContext, useEffect, useState } from 'react'

import { Avatar, Box, Divider, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, OutlinedInput, Skeleton } from '@mui/material'

import Drawer from '@mui/material/Drawer';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SendIcon from '@mui/icons-material/Send';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import StopCircleIcon from '@mui/icons-material/StopCircle';

import { DrawerHeaderRight } from './Appbar';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../GlobalContext';
import { initChat, sendMessage } from '../../utils/Gemini';

export const Right = ({title, component }) => {
    const { openRight, handlerSideRight } = useContext(GlobalContext)

    const location = useLocation()

    const [chat, setChat] = useState({})
    const [model, setModel] = useState({})
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    useEffect(() => {
        if(openRight){
            const { model, chat} = initChat()

            setChat(chat)
            setModel(model)
        }
        
    }, [openRight])

    return (
        <>
            <Drawer
                open={openRight}
                sx={{
                    overflow: 'hidden',
                    width: 400,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 400,
                    },
                }}
                variant="persistent"
                anchor="right"

            >
                <DrawerHeaderRight>
                    <IconButton onClick={() => handlerSideRight(false, location.pathname.replace('/', ''))}>
                        {openRight ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <h4 style={{position: 'absolute', left: 50}}>{ 'Asistente (beta)' }</h4>
                </DrawerHeaderRight>

                <Divider />

                <Box sx={{p: 2, overflow: 'hidden'}}>
                    <Box 
                        sx={{
                            p: 1, 
                            border: theme => `1px solid ${theme.palette.divider}`, 
                            borderRadius: 1,
                            height: 'calc(100vh - 262px)',
                            overflowY: 'auto',
                            //bgcolor: 'red'
                        }}
                    >
                        { chat?._history?.map((msg, index) => 
                            <Box key={index}>
                                { msg?.role === 'model' &&
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <AutoAwesomeIcon />
                                        </ListItemAvatar>
            
                                        <ListItemText
                                            secondary={msg?.parts[0]?.text}
                                        />
                                    </ListItem>
                                }

                                { msg?.role === 'user' && 
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            secondary={msg?.parts[0]?.text}
                                        />

                                        <ListItemAvatar>
                                            <Avatar />
                                        </ListItemAvatar>
                                    </ListItem>
                                }
                            </Box>
                        )}

                        { loading && 
                            <Box sx={{p: 1}}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton width={'60%'} />
                            </Box>
                        }
                    </Box>

                    <OutlinedInput
                        sx={{mt: 2.5}}
                        multiline
                        rows={4}
                        placeholder='En que puedo ayudarte?'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                                { loading ?
                                    <IconButton
                                        disabled={loading}
                                        onClick={() => setLoading(false)}
                                    >
                                        <StopCircleIcon />
                                    </IconButton>
                                    :
                                    <IconButton
                                        disabled={loading}
                                        onClick={() => {
                                            sendMessage(setLoading, chat, setChat, message)
                                            setMessage('')
                                        }}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                }
                                
                            </InputAdornment>
                        }
                    />
                </Box>
            </Drawer>
        </>
    )
}
