import { signingOut } from "./Login";
import { instance } from "./session/axios";

const changingPassword = async (
    setLoading, 
    setError,
    setPass,
    setNewPass,
    setConfirmPass,
    op, 
    np,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).settings.change_password){
        setLoading(true);

        const body = {
            user_id: JSON.parse(sessionStorage.getItem('account')).id,
            current_password: op,
            new_password: np
        }

        await instance.post('auth/change_password', body)
        .then(response => {
            handlerAlert(
                'settings.tab.tab1.list.list1.form.alerts.success_change_password.title', 
                'settings.tab.tab1.list.list1.form.alerts.success_change_password.message',
                'success',
            )
            
            setPass('')
            setNewPass('')
            setConfirmPass('')

            setError(false)

            setTimeout(() => {
                switch(JSON.parse(sessionStorage.getItem('account'))?.signin){
                    case 'MS_SSO':
                        signingOut(instance)
                        break
                    default:
                        console.log('signing out')
                        sessionStorage.clear()
                        localStorage.clear()
                        window.location.replace('/signin')
                }
            }, 5000)
            
        })
        .catch(error => { 
            console.error(error.message) 
            setError(true)
        })
        .finally(() => { 
            setLoading(false) 
        })
    }else{
        handlerAlert(
            'settings.tab.tab1.list.list1.form.alerts.change_password.title', 
                'settings.tab.tab1.list.list1.form.alerts.change_password.message',
            'error',
        )
    }
}

export {
    changingPassword
}